import moment from "moment";
import 'moment-timezone';
import {CHUNK_SIZE} from "../../../components/api/resources/resources-constants";
import angular from "angular";
import resources from "../../../components/api/resources/resources";
import translate from "angular-translate";
import localNetworkProfile from "../../../components/session/local-network-profile";
import _ from "lodash";
import {
  ADV_FIELDS,
  ADV_QUERY_FIELDS,
  ARTICLE_FIELDS,
  BURN_RATE_FIELDS,
  CDN_STATS_FIELDS,
  COLLECT_FIELDS,
  COLLECT_QUERY_FIELDS,
  EXTERNAL_AD_UNIT_QUERY_FIELDS, LINE_ITEM_QUERY_FIELDS,
  PUB_FIELDS,
  PUB_QUERY_FIELDS,
  SEGMENT_FIELDS,
  SEGMENT_QUERY_FIELDS, TARGETING_FIELDS
} from "./chart-options";
import {LocalNetworkInfo} from "../../../components/session/local-network-info";

const MODULE_NAME = "chartOptionsUtil";

angular.module(MODULE_NAME, [
  resources,
  translate,
  localNetworkProfile
])
  .factory('ChartOptionsUtil', function(LocalNetworkProfile, $filter) {

    const getNetworkTimezone = () => {
      return LocalNetworkProfile.get('timeZone') || "utc";
    };

    const getNowInTz = () => {
      return moment().tz(getNetworkTimezone());
    };

    const calcNow = () => {
      const now = getNowInTz(),
        duration = CHUNK_SIZE.HALF_HOURLY.duration.valueOf();

      return moment(Math.ceil(now.valueOf() / duration) * duration);
    };

    const lastBucket = _.bind(calcNow, null);

    const lastNHours = (n) => {
      let id = 'last' + n + 'Hour';
      if (n !== 1) {
        id += 's';
      }
      return {
        id: id,
        startDate: () => {
          return getNowInTz().subtract(n, 'h').startOf('h');
        },
        endDate: lastBucket
      };
    };

    const totalsFieldsMap = (data) => {
      return _.filter(_.map(data, (d) => {
        const another = _.assign({}, d);
        another.text = $filter('adnFieldTranslate')('report.field.' + (another.tKey || another.id));
        another.abbr = another.abbr ? undefined : $filter('adnFieldTranslate')('report.field.' + (another.tKey || another.id) + ".abbr");
        another.key = another.id;
        return another;
      }), function(d) {
        return d.id !== 'customFields';
      });
    };

    const advTotalsFields = totalsFieldsMap(_.assign({}, ADV_QUERY_FIELDS));
    const lineItemTotalsFields = totalsFieldsMap(_.assign({}, LINE_ITEM_QUERY_FIELDS));
    const topArticlesFields = totalsFieldsMap(_.assign({}, ARTICLE_FIELDS));
    const creativeOnLineItemTotalsFields = _.assign({}, advTotalsFields);
    const advCrossTotalsFields = _.filter(advTotalsFields, function(f) {
      return f.key !== 'uniqueUsers' && f.key !== 'impressionsPerUniqueUser';
    });

    const pubTotalsFields = totalsFieldsMap(_.assign({}, PUB_QUERY_FIELDS));
    const extAdUnitTotalsFields = totalsFieldsMap(_.assign({}, EXTERNAL_AD_UNIT_QUERY_FIELDS));
    const collectTotalsFields = totalsFieldsMap(_.assign({}, COLLECT_QUERY_FIELDS));
    const segmentTotalsFields = totalsFieldsMap(_.assign({}, SEGMENT_QUERY_FIELDS));
    const targetingFields = totalsFieldsMap(_.assign({}, TARGETING_FIELDS));

    const options = {
      ADVERTISER: {
        perfChartOptions: 'adnLineItemPerfChartOptions',
        queryColumnOptions: 'adnAdvertisingQueryOptions',
        targetingColumnOptions: 'adnTargetingQueryRowsOptions',
        chartTotalsOptions: 'adnLineItemChartTotalsOptions',
        fields: ADV_FIELDS,
        queryFields: ADV_QUERY_FIELDS,
        targetingFields: targetingFields,
        totalsFields: advTotalsFields,
        chartTotalsFields: advTotalsFields,
        idKey: 'advertiserId'
      },
      CREATIVE: {
        perfChartOptions: 'adnLineItemPerfChartOptions',
        queryColumnOptions: 'adnAdvertisingQueryOptions',
        adUnitRowsOptions: 'adnLineItemAdUnitRowsOptions',
        siteRowsOptions: 'adnLineItemSiteRowsOptions',
        targetingColumnOptions: 'adnTargetingQueryRowsOptions',
        chartTotalsOptions: 'adnLineItemChartTotalsOptions',
        targetingFields: targetingFields,
        chartTotalsFields: advTotalsFields,
        fields: ADV_FIELDS,
        queryFields: ADV_QUERY_FIELDS,
        totalsFields: advTotalsFields,
        adUnitFields: advCrossTotalsFields,
        siteFields: advCrossTotalsFields,
        idKey: 'creativeId'
      },
      ORDER: {
        perfChartOptions: 'adnLineItemPerfChartOptions',
        queryColumnOptions: 'adnAdvertisingQueryOptions',
        targetingColumnOptions: 'adnTargetingQueryRowsOptions',
        lineItemRowsOptions: 'adnLineItemCreativeRowsOptions',
        chartTotalsOptions: 'adnLineItemChartTotalsOptions',
        fields: ADV_FIELDS,
        queryFields: ADV_QUERY_FIELDS,
        targetingFields: targetingFields,
        chartTotalsFields: advTotalsFields,
        totalsFields: advTotalsFields,
        lineItemFields: lineItemTotalsFields,
        siteFields: advCrossTotalsFields,
        idKey: 'orderId'
      },
      FOLIO: {
        queryColumnOptions: 'adnAdvertisingQueryOptions',
        perfChartOptions: 'adnLineItemPerfChartOptions',
        creativeRowsOptions: 'adnFolioCreativeRowsOptions',
        creativeSetRowsOptions: 'adnFolioCreativeSetRowsOptions',
        adUnitRowsOptions: 'adnFolioAdUnitRowsOptions',
        targetingColumnOptions: 'adnTargetingQueryRowsOptions',
        chartTotalsOptions: 'adnLineItemChartTotalsOptions',
        fields: ADV_FIELDS,
        targetingFields: targetingFields,
        chartTotalsFields: advTotalsFields,
        queryFields: LINE_ITEM_QUERY_FIELDS,
        totalsFields: lineItemTotalsFields,
        creativeFields: creativeOnLineItemTotalsFields,
        adUnitFields: advCrossTotalsFields,
        idKey: 'folioId'
      },
      CREATIVE_SET: {
        queryColumnOptions: 'adnAdvertisingQueryOptions',
        perfChartOptions: 'adnLineItemPerfChartOptions',
        creativeRowsOptions: 'adnCreativeSetCreativeRowsOptions',
        adUnitRowsOptions: 'adnCreativeSetAdUnitRowsOptions',
        targetingColumnOptions: 'adnTargetingQueryRowsOptions',
        chartTotalsOptions: 'adnLineItemChartTotalsOptions',
        fields: ADV_FIELDS,
        targetingFields: targetingFields,
        chartTotalsFields: advTotalsFields,
        queryFields: LINE_ITEM_QUERY_FIELDS,
        totalsFields: lineItemTotalsFields,
        creativeFields: creativeOnLineItemTotalsFields,
        adUnitFields: advCrossTotalsFields,
        idKey: 'creativeSetId'
      },
      LINE_ITEM: {
        queryColumnOptions: 'adnAdvertisingQueryOptions',
        perfChartOptions: 'adnLineItemPerfChartOptions',
        creativeRowsOptions: 'adnLineItemCreativeRowsOptions',
        siteRowsOptions: 'adnLineItemSiteRowsOptions',
        adUnitRowsOptions: 'adnLineItemAdUnitRowsOptions',
        targetingColumnOptions: 'adnTargetingQueryRowsOptions',
        chartTotalsOptions: 'adnLineItemChartTotalsOptions',
        fields: ADV_FIELDS,
        targetingFields: targetingFields,
        chartTotalsFields: advTotalsFields,
        queryFields: LINE_ITEM_QUERY_FIELDS,
        totalsFields: lineItemTotalsFields,
        topArticlesFields: topArticlesFields,
        creativeFields: creativeOnLineItemTotalsFields,
        adUnitFields: advCrossTotalsFields,
        siteFields: advCrossTotalsFields,
        idKey: 'lineItemId'
      },
      COLLECT: {
        perfChartOptions: 'adnCollectPerfChartOptions',
        queryColumnOptions: 'adnCollectQueryOptions',
        fields: COLLECT_FIELDS,
        queryFields: COLLECT_QUERY_FIELDS,
        totalsFields: collectTotalsFields,
        idKey: 'folderId'
      },
      FOLDER: {
        perfChartOptions: 'adnCollectPerfChartOptions',
        queryColumnOptions: 'adnCollectQueryOptions',
        fields: COLLECT_FIELDS,
        queryFields: COLLECT_QUERY_FIELDS,
        totalsFields: collectTotalsFields,
        idKey: 'folderId'
      },
      AD_UNIT: {
        type: 'PUBLISHING',
        queryColumnOptions: 'adnPublishingQueryOptions',
        perfChartOptions: 'adnAdUnitPerfChartOptions',
        lineItemRowsOptions: 'adnAdUnitLineItemRowsOptions',
        creativeRowsOptions: 'adnAdUnitCreativeRowsOptions',
        targetingColumnOptions: 'adnTargetingQueryRowsOptions',
        chartTotalsOptions: 'adnAdUnitChartTotalsOptions',
        targetingFields: targetingFields,
        fields: PUB_FIELDS,
        queryFields: PUB_QUERY_FIELDS,
        totalsFields: pubTotalsFields,
        lineItemFields: advCrossTotalsFields,
        creativeFields: advCrossTotalsFields,
        idKey: 'adUnitId'
      },
      SITE: {
        type: 'PUBLISHING',
        queryColumnOptions: 'adnPublishingQueryOptions',
        perfChartOptions: 'adnAdUnitPerfChartOptions',
        lineItemRowsOptions: 'adnSiteLineItemRowsOptions',
        targetingColumnOptions: 'adnTargetingQueryRowsOptions',
        chartTotalsOptions: 'adnAdUnitChartTotalsOptions',
        fields: PUB_FIELDS,
        targetingFields: targetingFields,
        queryFields: PUB_QUERY_FIELDS,
        totalsFields: pubTotalsFields,
        lineItemFields: advCrossTotalsFields,
        idKey: 'siteId'
      },
      EXTERNAL_AD_UNIT: {
        type: 'PUBLISHING',
        perfChartOptions: 'adnExternalAdUnitPerfChartOptions',
        siteRowsOptions: 'adnExternalAdUnitSiteRowsOptions',
        adUnitRowsOptions: 'adnExternalAdUnitAdUnitRowsOptions',
        chartTotalsOptions: 'adnExternalAdUnitChartTotalsOptions',
        fields: EXTERNAL_AD_UNIT_QUERY_FIELDS,
        totalsFields: extAdUnitTotalsFields,
        idKey: 'externalAdUnitId',
        adUnitFields: extAdUnitTotalsFields,
        siteFields: extAdUnitTotalsFields
      },
      EXTERNAL_DEMAND_SOURCE: {
        perfChartOptions: 'adnExternalDemandSourcePerfChartOptions',
        fields: EXTERNAL_AD_UNIT_QUERY_FIELDS,
        totalsFields: extAdUnitTotalsFields,
        idKey: 'externalDemandSourceId',
        adUnitFields: extAdUnitTotalsFields,
        siteFields: extAdUnitTotalsFields
      },
      EARNINGS_ACCOUNT: {
        type: 'PUBLISHING',
        queryColumnOptions: 'adnPublishingQueryOptions',
        perfChartOptions: 'adnAdUnitPerfChartOptions',
        targetingColumnOptions: 'adnTargetingQueryRowsOptions',
        chartTotalsOptions: 'adnAdUnitChartTotalsOptions',
        targetingFields: targetingFields,
        lineItemRowsOptions: 'adnEarningsAccountLineItemRowsOptions',
        lineItemFields: advCrossTotalsFields,
        fields: PUB_FIELDS,
        queryFields: PUB_QUERY_FIELDS,
        totalsFields: pubTotalsFields,
        idKey: 'earningsAccountId'
      },
      SEGMENT: {
        perfChartOptions: 'adnSegmentPerfChartOptions',
        fields: SEGMENT_FIELDS,
        totalsFields: segmentTotalsFields,
        idKey: 'segmentId'
      },
      USER_SEGMENT: {
        fields: ADV_FIELDS,
        lineItemRowsOptions: 'adnUserSegmentLineItemRowsOption',
        queryColumnOptions: 'adnUserSegmentQueryOptions',
        totalsFields: advCrossTotalsFields,
        lineItemFields: advCrossTotalsFields,
        idKey: 'segmentId'
      },
      BURN_RATE: {
        fields: BURN_RATE_FIELDS
      },
      CDN_STATS: {
        perfChartOptions: 'adnCdnStatsOptions',
        fields: CDN_STATS_FIELDS
      }
    };

    const getTheChunkSize = function(startDate, endDate, isTargeting) {
      const hourlyDays = isTargeting ? 14 : 44;
      if (startDate && endDate) {
        const dateDiff = Math.abs(moment(startDate).diff(moment(endDate), 'days'));
        const daysFromNow = Math.max(Math.abs(moment(startDate).diff(moment(), 'days')), Math.abs(moment(endDate).diff(moment(), 'days')));
        if (dateDiff < 2 && daysFromNow < hourlyDays) {
          return CHUNK_SIZE.HALF_HOURLY;
        }
        if (dateDiff < 5 && daysFromNow < hourlyDays) {
          return CHUNK_SIZE.HOURLY;
        }
        if (dateDiff < 65) {
          return CHUNK_SIZE.DAILY;
        }
        if (dateDiff < 350) {
          return CHUNK_SIZE.WEEKLY;
        }
        return CHUNK_SIZE.MONTHLY;
      }
      return CHUNK_SIZE.MONTHLY;
    };

    function skipFields(theOptions, checkField) {
      const skippableFields = _.filter(theOptions.fields, function(f) {
        return f[checkField];
      });
      _.forEach(Object.keys(theOptions), function(param) {
        if (_.endsWith(param, "ields")) {
          _.forEach(skippableFields, function(f) {
            if (theOptions[param][f.id]) {
              delete theOptions[param][f.id];
            } else {
              theOptions[param] = _.filter(theOptions[param], function(theOptionF) {
                return theOptionF.id !== f.id;
              });
            }
          });
        }
      });
      return theOptions;
    }

    return {
      getCustomFieldsAverage: function() {
        return _.map(_.filter(LocalNetworkInfo.obtain().getInfo("customEvents"), function(ce) {
          return ce.type === 'SUM';
        }), 'name');
      },
      getSplitFees: function(dataPoint) {
        const splitFees = [];
        _.forEach(_.get(dataPoint, ['feeBreakdown']), function(v, k) {
          splitFees.push({name: k, val: v});
        });
        return splitFees;
      },
      getLineItemCustomFields: function(dataPoint) {
        const customFields = [];
        const customEventsInOrder = LocalNetworkInfo.obtain().getInfo("customEvents");
        _.forEach(dataPoint, function(v, k) {
          if (_.get(v, 'id')) {
            const match = _.find(customEventsInOrder, function(ce) {
              return ce.id === v.id;
            });
            let priority = _.get(match, ['priority'], 0);
            customFields.push({ name: k, priority: priority === 0 ? Number.MAX_SAFE_INTEGER : priority });
          }
        });

        let sortedCustom = _.sortBy(customFields, ['priority']);
        return _.map(sortedCustom, 'name');
      },
      timeShift: function(theDate, toBrowserTime) {
        //let preferredTz = getNetworkTimezone().utcOffset();
        let browserTz = moment(new Date()).utcOffset();
        return moment(theDate).add(browserTz * (toBrowserTime ? -1 : 1), "minute");
      },
      getNetworkTimezone: function() {
        return getNetworkTimezone();
      },
      getChunkSize: function(startDate, endDate, absoluteChunkMin, isTargeting) {
        const chunk = getTheChunkSize(startDate, endDate, isTargeting);
        const minChunk = absoluteChunkMin ? CHUNK_SIZE[absoluteChunkMin] : null;
        if (minChunk) {
          return minChunk.closestMinutes > chunk.closestMinutes ? minChunk : chunk;
        }
        return chunk;
      },
      getOptions: function(objType) {
        const theOptions = options[objType];
        if (theOptions && theOptions.fields) {
          if (!LocalNetworkProfile.isPureMarketplacePlatform()) {
            skipFields(theOptions, 'marketplaceOnly');
          }
          if (!LocalNetworkProfile.isGdprEnabled()) {
            skipFields(theOptions, 'gdprEnabledOnly');
          }
        }
        return theOptions;
      },
      getDateOptions: function(chartType) {
        const dateOptions = {
          last6Hours: lastNHours(6),
          last12Hours: lastNHours(12),
          last24Hours: lastNHours(24),
          today: {
            id: 'today',
            startDate: () => {
              return getNowInTz().startOf('day');
            },
            endDate: lastBucket
          },
          yesterday: {
            id: 'yesterday',
            startDate: () => {
              return getNowInTz().startOf('day').subtract(1, 'd');
            },
            endDate: () => {
              return getNowInTz().subtract(1, 'd').endOf('day');
            }
          },
          last7Days: {
            id: 'last7Days',
            startDate: () => {
              return getNowInTz().startOf('day').subtract(7, 'd');
            },
            endDate: lastBucket
          },
          thisWeek: {
            id: 'thisWeek',
            startDate: () => {
              const defaults = LocalNetworkProfile.getDefaults();
              const startDay = defaults.startDayCalendar;
              return _.isFinite(startDay) ? getNowInTz().isoWeekday(startDay).startOf('isoWeek') : getNowInTz().startOf('week');
            },
            endDate: lastBucket
          },
          lastWeek: {
            id: 'lastWeek',
            startDate: () => {
              const defaults = LocalNetworkProfile.getDefaults();
              const startDay = defaults.startDayCalendar;
              const beginningPoint = getNowInTz().subtract(1, 'week');
              return _.isFinite(startDay) ? beginningPoint.isoWeekday(startDay).startOf('isoWeek') : beginningPoint.startOf('week');
            },
            endDate: () => {
              const defaults = LocalNetworkProfile.getDefaults();
              const startDay = defaults.startDayCalendar;
              const beginningPoint = getNowInTz().subtract(1, 'week');
              return _.isFinite(startDay) ? beginningPoint.isoWeekday(startDay).endOf('isoWeek') : beginningPoint.endOf('week');
            }
          },
          last30Days: {
            id: 'last30Days',
            startDate: () => {
              return getNowInTz().startOf('day').subtract(30, 'd');
            },
            endDate: lastBucket
          },
          thisMonth: {
            id: 'thisMonth',
            startDate: () => {
              return getNowInTz().startOf('month');
            },
            endDate: lastBucket
          },
          lastMonth: {
            id: 'lastMonth',
            startDate: () => {
              return getNowInTz().subtract(1, 'month').startOf('month');
            },
            endDate: () => {
              return getNowInTz().subtract(1, 'month').endOf('month').endOf('day');
            }
          },
          last6Months: {
            id: 'last6Months',
            startDate: () => {
              return getNowInTz().subtract(6, 'month').startOf('day');
            },
            endDate: lastBucket
          },
          last12Months: {
            id: 'last12Months',
            startDate: () => {
              return getNowInTz().subtract(12, 'month').startOf('month');
            },
            endDate: lastBucket
          },
          thisYear: {
            id: 'thisYear',
            startDate: () => {
              return getNowInTz().startOf('year');
            },
            endDate: lastBucket
          },
          lastYear: {
            id: 'lastYear',
            startDate: () => {
              return getNowInTz().subtract(1, 'year').startOf('year');
            },
            endDate: () => {
              return getNowInTz().subtract(1, 'year').endOf('year').endOf('day');
            }
          }
        };
        if (chartType === 'ORDER' || chartType === 'LINE_ITEM' || chartType === 'CREATIVE') {
          dateOptions.useDates = {
            id: 'useDates',
            startDate: function() {
              return undefined;
            },
            endDate: function() {
              return undefined;
            },
            undated: true
          };
        }
        return dateOptions;
      }
    };
  });

export default MODULE_NAME;