/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';

import localNetworkProfile from '../../../components/session/local-network-profile';
import localUserPermissions from '../../../components/session/local-user-permissions';
import trafficComponent from '../../common/directives/related-data-chart-component';
import timeShifter from '../../../components/util/time-shifter';
import standardFormModule from '../../common/standard-form-module';
import standardAnalysisModule from '../../common/standard-analysis-module';
import {adnFormHelper} from "../../common/controller/form-helper";

const MODULE_NAME = "data-export-controller";

angular.module(MODULE_NAME, [
  standardFormModule,
  standardAnalysisModule,
  timeShifter,
  localNetworkProfile,
  localUserPermissions,
  trafficComponent
])

  .controller('DataExportCtrl', function($stateParams, LocalUserPermissions, LocalNetworkProfile, Team, adnTimeShifter, localUserProfile, model, modelComms, searchResource) {
    const ctrl = this;

    ctrl.isNew = $stateParams.isNew;
    ctrl.app = localUserProfile.getCurrentApp();
    ctrl.perms = LocalUserPermissions.getAllVisPermissions();
    ctrl.currencies = LocalNetworkProfile.get('availableCurrencies');

    const doFields = function() {
      ctrl.fields = {
        SEGMENT_DEFINITIONS: _.get(ctrl.fields, 'SEGMENT_DEFINITIONS') || [],
        USER_SEGMENTS: _.get(ctrl.fields, 'USER_SEGMENTS') || [],
        USER_PROFILES: _.get(ctrl.fields, 'USER_PROFILES') || [],
        RAW_LOG: _.get(ctrl.fields, 'RAW_LOG') || []
      };

      ctrl.fields[ctrl.model.source] = ctrl.model.fields || [];
    };

    const updateAllTeams = function() {
      const params = {minimal: true};
      if (ctrl.perms.AX_ADVERTISER_ADMIN === true) {
        params.filterBy = 'type';
        params.filterByValue = 'AX_ADVERTISER';
      }
      if (ctrl.perms.AX_PUBLISHER_ADMIN === true) {
        params.filterBy = 'type';
        params.filterByValue = 'AX_PUBLISHER';
      }
      Team.query(params).$promise.then(function(page) {
        ctrl.allTeams = page.results || [];
      });
    };

    const doParsing = function(data) {
      if (ctrl.model.format !== 'JSON') {
        return data;
      }
      try {
        return JSON.parse(data);
      } catch (e) {
        return data;
      }
    };

    const statsFields = [
      'Start',
      'Publisher',
      'Publisher_Id',
      'Site',
      'Site_Id',
      'Ad_Unit',
      'Ad_Unit_Id',
      'Ad_Unit_Labels',
      'Advertiser',
      'Advertiser_Id',
      'Order',
      'Order_Id',
      'Order_Team',
      'Order_Team_Id',
      'Line_Item',
      'Line_Item_Id',
      'Creative',
      'Creative_Id',
      'Creative_Source',
      'Creative_Size',
      'Available_Traffic',
      'Losing_Bids',
      'Impressions',
      'Rendered_Impressions',
      'Visibles',
      'Visibility',
      'Viewables',
      'Viewability',
      'Clicks',
      'Conversions',
      'CTR',
      'Currency',
      'eCPM',
      'vCPM',
      'Cost',
      'Total_Cost'
    ];

    const azureEnvironments = [
      'AZURE',
      'AZURE_CHINA',
      'AZURE_US_GOVERNMENT',
      'AZURE_GERMANY'
    ];

    const awsRegions = [
      'EU_CENTRAL_1',
      'EU_CENTRAL_2',
      'EU_NORTH_1',
      'EU_SOUTH_1',
      'EU_SOUTH_2',
      'AP_EAST_1',
      'AP_SOUTH_1',
      'AP_SOUTH_2',
      'AP_SOUTHEAST_1',
      'AP_SOUTHEAST_2',
      'AP_SOUTHEAST_3',
      'AP_SOUTHEAST_4',
      'AP_NORTHEAST_1',
      'AP_NORTHEAST_2',
      'AP_NORTHEAST_3',
      'SA_EAST_1',
      'CN_NORTH_1',
      'CN_NORTHWEST_1',
      'CA_CENTRAL_1',
      'CA_WEST_1',
      'GovCloud',
      'US_GOV_EAST_1',
      'US_EAST_1',
      'US_EAST_2',
      'US_WEST_1',
      'US_WEST_2',
      'EU_WEST_1',
      'EU_WEST_2',
      'EU_WEST_3',
      'ME_CENTRAL_1',
      'ME_SOUTH_1',
      'AF_SOUTH_1',
      'US_ISO_EAST_1',
      'US_ISOB_EAST_1',
      'US_ISO_WEST_1',
      'IL_CENTRAL_1'
    ];

    const allEventTypes = [
      {id: "IMPRESSION", directory: "IMPRESSION", transKey: 'impression'},
      {id: "IN_BROWSER", directory: "RENDERED", transKey: 'rendered'},
      {id: "VISIBLE", directory: "VISIBLE", transKey: 'Visible Impressions'},
      {id: "VIEWABLE", directory: "VIEWABLE", transKey: 'Viewable Impressions'},
      {id: "CLICK", directory: "CLICK", transKey: 'Clicks'},
      {id: "CUSTOM", directory: "CONVERSION", transKey: 'Conversions'}
    ];

    adnFormHelper.setUpForm(ctrl, model, {
      modelComms: modelComms,
      afterInit: function() {
        ctrl.allEventTypes = allEventTypes;
        ctrl.statsFields = statsFields;
        ctrl.awsRegions = awsRegions;
        ctrl.azureEnvironments = azureEnvironments;
        // if not a marketplace network, we do not want the Publisher field
        if (!LocalNetworkProfile.isMarketplacePlatform()) {
          const index = ctrl.statsFields.indexOf('Publisher');
          ctrl.statsFields.splice(index, 1);
        }
        ctrl.model.sftpSettings = ctrl.model.sftpSettings || {};
        ctrl.model.csvSettings = ctrl.model.csvSettings || {};
        ctrl.model.awsSettings = ctrl.model.awsSettings || {};
        ctrl.model.azureSettings = ctrl.model.azureSettings || {};
        ctrl.model.gcsSettings = ctrl.model.gcsSettings || {};
        ctrl.model.sheetSettings = ctrl.model.sheetSettings || {};
        ctrl.model.statsSettings = ctrl.model.statsSettings || {};

        if (ctrl.model.scheduleTime) {
          const dateFormat = moment().format("YYYY-MM-DD ");
          const workingDate = _.isDate(ctrl.model.scheduleTime) ? ctrl.model.scheduleTime : moment.tz(dateFormat + ctrl.model.scheduleTime, LocalNetworkProfile.get('timeZone') || 'utc');
          ctrl.model.modScheduleTime = workingDate === null ? null : moment(adnTimeShifter.timeShift(workingDate, true));
        }

        if (ctrl.isNew) {
          ctrl.model.data = ctrl.model.data || 'DELTAS';
          ctrl.model.format = ctrl.model.format || 'CSV';
          ctrl.model.type = ctrl.model.type || ctrl.app === 'DATA' ? 'SFTP' : 'S3';
          if (ctrl.model.type === 'S3') {
            ctrl.model.awsSettings.region = ctrl.model.awsSettings.region || 'EU_CENTRAL_1';
          }
          ctrl.model.schedule = ctrl.model.schedule || 'DAILY';
          ctrl.model.compression = ctrl.model.compression || 'GZIP';
          if (ctrl.app === 'DATA') {
            ctrl.model.source = ctrl.model.source || 'USER_SEGMENTS';
          } else if (ctrl.perms.MANAGE_SYSTEM === true && ctrl.perms.DOWNLOAD_LOGS !== true) {
            ctrl.model.source = ctrl.model.source || 'STATS';
          } else if (ctrl.perms.AX_ADVERTISER_ADMIN === true || ctrl.perms.AX_PUBLISHER_ADMIN === true) {
            ctrl.model.source = ctrl.model.source || 'STATS';
          } else {
            ctrl.model.source = ctrl.model.source || 'RAW_LOG';
          }
          ctrl.model.csvSettings.separator = ctrl.model.csvSettings.separator || 'COMMA';
          ctrl.model.csvSettings.arraySeparator = ctrl.model.csvSettings.arraySeparator || 'TAB';
          ctrl.model.csvSettings.header = ctrl.model.csvSettings.header || false;
          ctrl.model.statsSettings.filterType = ctrl.model.statsSettings.filterType || undefined;
          ctrl.model.statsSettings.fields = ctrl.statsFields;
          ctrl.model.statsSettings.useLineItemCurrency = ctrl.model.statsSettings.useLineItemCurrency || false;
        }

        if (ctrl.model.source === 'STATS') {
          ctrl.model.type = ctrl.model.type || 'SHEET';
          if (ctrl.model.statsSettings.filterType === 'TEAM') {
            updateAllTeams();
            ctrl.model.statsSettings.teams = _.map(ctrl.model.statsSettings.teams, 'id');
          }
        } else if (ctrl.model.source === 'RAW_LOG') {
          updateAllTeams();
        }
        doFields();
        ctrl.model.teams = _.map(ctrl.model.teams, 'id');
        ctrl.model.example = doParsing(ctrl.model.example);
      },
      afterSave: function(savedObj) {
        doFields();
        ctrl.model.example = doParsing(savedObj.example);
        _.set(ctrl.model, 'teams', _.map(_.get(savedObj, 'teams') || [], 'id'));
        _.set(ctrl.model, 'createUser', savedObj.createUser);
      },
      afterInitAfterSave: function() {
        ctrl.eventTypeOption = !ctrl.model.eventTypes || ctrl.model.eventTypes.length === 0 ? 'ALL' : 'SELECTIVE';
      }
    });

    ctrl.addField = function() {
      ctrl.editsMade();
      let defaultField = {editable: true};
      if (ctrl.model.source === 'SEGMENT_DEFINITIONS') {
        defaultField.source = 'SEGMENT_ID';
      } else if (ctrl.model.source === 'USER_SEGMENTS') {
        defaultField.source = 'SEGMENTS_ADNUNTIUS_USER_ID';
      } else {
        defaultField.source = 'CONSTANT';
      }
      ctrl.fields[ctrl.model.source].push(defaultField);
    };

    ctrl.deleteField = function(index) {
      ctrl.editsMade();
      _.pullAt(ctrl.fields[ctrl.model.source], index);
    };

    ctrl.isVisible = function(source) {
      switch (source) {
        case 'SEGMENT_DEFINITIONS':
        case 'USER_SEGMENTS':
        case 'USER_PROFILES':
          return ctrl.app === 'DATA';
        case 'RAW_LOG':
          return ctrl.app === 'ADVERTISING' && ctrl.perms.DOWNLOAD_LOGS === true;
        case 'STATS':
          return ctrl.app === 'ADVERTISING' && (ctrl.perms.AX_ADVERTISER_ADMIN === true || ctrl.perms.AX_PUBLISHER_ADMIN === true || ctrl.perms.MANAGE_SYSTEM === true);
        default:
          return false;
      }
    };

    ctrl.advertiserSearch = function(searchElement) {
      searchResource.query({"q": searchElement.search, "types": "Advertiser"}).then(function(data) {
        ctrl.advertiserSearchResults = data.searchResults;
      });
    };

    ctrl.orderSearch = function(searchElement) {
      searchResource.query({"q": searchElement.search, "types": "Order"}).then(function(data) {
        ctrl.orderSearchResults = data.searchResults;
      });
    };

    ctrl.updateStatsFilterType = function() {
      switch (ctrl.model.statsSettings.filterType) {
        case 'TEAM':
          updateAllTeams();
          ctrl.model.statsSettings.advertisers = [];
          ctrl.model.statsSettings.orders = [];
          break;
        case 'ADVERTISER':
          ctrl.model.statsSettings.teams = [];
          ctrl.model.statsSettings.orders = [];
          break;
        case 'ORDER':
          ctrl.model.statsSettings.teams = [];
          ctrl.model.statsSettings.advertisers = [];
          break;
        default:
          ctrl.model.statsSettings.filterType = undefined;
          ctrl.model.statsSettings.teams = [];
          ctrl.model.statsSettings.advertisers = [];
          ctrl.model.statsSettings.orders = [];
          break;
      }
    };

    ctrl.updateLineItemCurrency = function() {
      ctrl.model.statsSettings.currency = null;
    };

    ctrl.updateDestination = function () {
      switch (ctrl.model.type) {
        case 'AZURE_STORAGE_BLOB':
          ctrl.model.azureSettings.environment = ctrl.model.azureSettings.environment || 'AZURE';
          break;
        case 'S3':
          ctrl.model.awsSettings.region = ctrl.model.awsSettings.region || 'EU_CENTRAL_1';
          break;
        case 'S3_COMPATIBLE_BUCKET':
          ctrl.model.awsSettings.region = undefined;
          break;
      }
    };

    ctrl.updateSource = function() {
      switch (ctrl.model.source) {
        case 'SEGMENT_DEFINITIONS':
        case 'USER_SEGMENTS':
        case 'USER_PROFILES':
          if (ctrl.model.type === 'S3') {
            ctrl.model.type = 'SFTP';
          }
          break;
        case 'RAW_LOG':
          ctrl.model.type = 'S3';
          if (ctrl.model.schedule === 'WEEKLY') {
            ctrl.model.schedule = 'DAILY';
          }
          break;
        case 'STATS':
          ctrl.model.type = 'SHEET';
          ctrl.model.data = 'DELTAS';
          ctrl.model.schedule = 'DAILY';
          break;
        default:
      }
    };

    ctrl.onBeforeSubmit = function() {
      ctrl.model.scheduleTime = _.isDate(ctrl.model.modScheduleTime) ? moment(adnTimeShifter.timeShift(ctrl.model.modScheduleTime)).format("HH:mm:ss") : null;

      ctrl.model.fields = _.map(_.filter(ctrl.fields[ctrl.model.source], function(f) {
        return f.name && f.source;
      }), function(f) {
        delete f.editable;
        return f;
      });
    };
  });

export default MODULE_NAME;