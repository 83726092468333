/**
 * Copyright © 2024 Adnuntius AS.
 */
const ADN_TRANSLATIONS = {
  advertiser: {
    advertiser: 'Advertiser',
    plural: 'Advertisers',
    new: 'New Advertiser',
    widget: {
      reportRequester: 'Reports',
      reportSchedule: 'Report Schedules'
    }
  },
  days: {
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday',
    SUNDAY: 'Sunday'
  },
  blocklist: {
    blocklist: 'Blocklist',
    plural: 'Blocklists'
  },
  dataExport: {
    dataExport: 'Data Export',
    plural: 'Data Exports',
    dataForExport: {
      DELTAS: 'Deltas',
      ALL_RECORDS: 'All'
    },
    type: {
      SFTP: 'SFTP',
      WEB_HOOK: 'Web Hook',
      PLATFORM_161: 'Platform 161',
      SHEET: 'Google Sheets',
      S3: 'S3',
      GCS: 'Google Cloud',
      S3_COMPATIBLE_BUCKET: 'S3 Compatible Bucket',
      AZURE_STORAGE_BLOB: 'Azure'
    },
    compression: {
      GZIP: 'GZIP',
      NONE: 'None'
    },
    schedule: {
      HOURLY: 'Hourly',
      DAILY: 'Daily',
      WEEKLY: 'Weekly'
    },
    dataSource: {
      USER_SEGMENTS: 'User Segments',
      USER_PROFILES: 'User Profiles',
      SEGMENT_DEFINITIONS: 'Segment Definitions',
      RAW_LOG: 'Raw Log',
      STATS: 'Stats'
    },
    fieldSource: {
      SEGMENT_ID: 'Segment ID',
      SEGMENT_NAME: 'Segment Name',
      SEGMENT_DESCRIPTION: 'Segment Description',
      SEGMENTS_ADNUNTIUS_USER_ID: 'Adnuntius User ID',
      SEGMENTS_EXTERNAL_USER_ID: 'External System User ID',
      SEGMENTS_LIST: 'List of Segments',
      CONSTANT: 'Constant'
    },
    statsField: {
      Start: 'Date',
      Ad_Unit: 'Ad Unit Name',
      Ad_Unit_Id: 'Ad Unit ID',
      Ad_Unit_Labels: 'Ad Unit Labels',
      Creative: 'Creative Name',
      Creative_Id: 'Creative ID',
      Line_Item: 'Line Item Name',
      Line_Item_Id: 'Line Item ID',
      Advertiser: 'Advertiser Name',
      Advertiser_Id: 'Advertiser ID',
      Order: 'Order Name',
      Order_Id: 'Order ID',
      Order_Team: 'Order Team Name',
      Order_Team_Id: 'Order Team ID',
      Site: 'Site Name',
      Site_Id: 'Site ID',
      Publisher: 'Publisher Name',
      Publisher_Id: 'Publisher ID',
      Creative_Source: 'Creative Source',
      Creative_Size: 'Creative Size',
      Available_Traffic: 'Available Traffic',
      Losing_Bids: 'Losing Bids',
      Impressions: 'Impressions',
      Rendered_Impressions: 'Rendered Impressions',
      Visibles: 'Visibles',
      Visibility: 'Visibility',
      Viewables: 'Viewables',
      Viewability: 'Viewability',
      Clicks: 'Clicks',
      Conversions: 'Conversions',
      Currency: 'Currency',
      CTR: 'CTR',
      eCPM: 'eCPM',
      vCPM: 'vCPM',
      Cost: 'Cost',
      Total_Cost: 'Total Cost'
    }
  },
  eventTypes: {
    plural: 'Event Types'
  },
  payment: {
    payment: 'Payment',
    plural: 'Payments',
    state: {
      CREATED: 'Created',
      NOT_AUTHORISED: 'Not Authorised',
      AUTHORISED: 'Authorised',
      COMPLETED: 'Completed',
      CANCELLED: 'Cancelled',
      FAILED: 'Failed'
    }
  },
  axProduct: {
    axProduct: 'Marketplace Product',
    plural: 'Marketplace Products',
    smoothingOptions: {
      optionEf: 'Even, Front Loaded',
      optionEfs: 'Even, Front Loaded, Strict Cap (Default)',
      optionEfcc: 'Even, Front Loaded, Strict Cap, Catch Up',
      optionEfccg: 'Even, Front Loaded, Strict Cap, Catch Up, Front Running',
      optionUef: 'Unsmoothed, Even, Front Loaded',
      optionUefs: 'Unsmoothed, Even, Front Loaded, Strict Cap',
      optionE: 'Even',
      optionC: 'Strict Cap',
      optionF: 'Unsmoothed'
    }
  },
  paymentProvider: {
    PAYPAL: 'PayPal',
    PAYPAL_SANDBOX: 'PayPal Sandbox',
    PIN_PAYMENTS: 'Pin Payments',
    PIN_PAYMENTS_SANDBOX: 'Pin Payments Sandbox',
    MANUAL_INVOICING: 'Manual Invoicing'
  },
  trigger: {
    trigger: 'Trigger',
    plural: 'Triggers',
    autoRemove: {
      checkbox: 'Automatically remove segment when trigger does not match'
    },
    behaviour: {
      type: {
        PAGE_VIEW: {
          name: 'domain',
          verb: 'visited'
        },
        KEYWORD: {
          name: 'keyword',
          verb: 'saw'
        },
        CATEGORY: {
          name: 'category',
          verb: 'saw'
        },
        DEVICE_OS: {
          name: 'device operating system',
          verb: 'used'
        },
        DEVICE_TYPE: {
          name: 'device type',
          verb: 'used'
        },
        COUNTRY: {
          name: 'country',
          verb: 'were located in'
        },
        REGION: {
          name: 'region',
          verb: 'were located in'
        },
        DISTRICT: {
          name: 'district',
          verb: 'were located in'
        },
        CITY: {
          name: 'city',
          verb: 'were located in'
        },
        CLICKED_CATEGORY: {
          name: 'clicked on advertiser category',
          verb: 'clicked'
        },
        CONVERTED_CATEGORY: {
          name: 'converted on advertiser category',
          verb: 'converted'
        }
      }
    },
    pageView: {
      time: {
        TODAY: 'today',
        YESTERDAY: 'yesterday',
        THIS_WEEK: 'this week',
        LAST_7_DAYS: 'last 7 days',
        LAST_WEEK: 'last week',
        LAST_14_DAYS: 'last 14 days',
        THIS_MONTH: 'this month',
        LAST_30_DAYS: 'last 30 days'
      }
    }
  },
  dateGrouping: {
    DAY: 'Daily',
    WEEK: 'Weekly',
    MONTH: 'Monthly'
  },
  segment: {
    segment: 'Segment',
    plural: 'Segments',
    cpm: 'CPM'
  },
  mapping: {
    mapping: 'Mapping',
    plural: 'Mappings',
    KEYWORD: 'Page View Keywords',
    CATEGORY: 'Page View Categories'
  },
  folder: {
    segment: 'Folder',
    plural: 'Folders'
  },
  adUnit: {
    adUnit: 'Ad Unit',
    plural: 'Ad Units',
    addNewSpaceFiller: 'This area will be filled with charts and ad tags once you\'ve saved your new ad unit',
    widget: {
      notes: 'Notes',
      reportRequester: '@:lineItem.widget.reportRequester',
      reportSchedule: '@:lineItem.widget.reportSchedule'
    },
    tag: {
      tag: 'Ad Tags',
      vast: {
        vast: 'VAST'
      },
      email: {
        email: 'Email'
      },
      native: 'Native Ads',
      COM: {
        COM: "Standard",
        desc: "<p>The standard tag allows one or more ad units on a page to be served with ads via a single ad request to the Adnuntius ad server.</p>",
        desc2: "<p>The example above shows how to serve an ad into this specific ad unit. Add references to any other ad units on your publisher's page to have them all fill with ads served by the same ad request to the Adnuntius ad server.</p>" +
          "<p>Although it's more complex to set up, serving ads into many ad units via a single ad request has two big advantages:</p><ul><li>ads appear in your ad units faster;</li><li>deduplication is possible, which prevents the same or similar ads appearing on the same page.</li></ul>",
      },
      IFR: {
        IFR: "Basic",
        desc: "<p>The easiest way to get an ad onto a website. Just cut-and-paste the code snippet below into your page and off you go.</p>",
        desc2: ''
      }
    }
  },
  apiKey: {
    apiKey: 'API Key',
    plural: 'API Keys',
    scope: {
      SEGMENT_UPLOAD: 'Upload User Segments',
      STATS_QUERY: 'Query Stats',
      ADVERTISING_QUERY: 'Query Advertising',
      ADVERTISING_UPDATE: 'Update Advertising',
      LOG_DOWNLOAD: 'Download Ad Server Logs',
      BIDDER: 'Bidding Algorithm'
    }
  },
  app: {
    DATA: {
      DATA: 'Adnuntius Data'
    },
    ADVERTISING: {
      ADVERTISING: 'Adnuntius Advertising'
    },
    SELF_SERVICE: 'Adnuntius Self-Service'
  },
  coupon: {
    coupon: 'Coupon',
    plural: 'Coupons',
    couponStatus: {
      PUBLISHED: 'Published',
      PENDING: 'Pending',
      STOPPED: 'Stopped'
    }
  },
  asset: {
    asset: 'Asset',
    plural: 'Assets'
  },
  targeting: {
    TARGETING_DEVICE: 'Device',
    TARGETING_DEVICE_PROPERTY: 'Device',
    TARGETING_NAMED_LOCATION: 'Location',
    TARGETING_GEOSPATIAL: 'Geospatial Area',
    TARGETING_CATEGORY: 'Category',
    TARGETING_KEY_VALUE: 'Key-Value',
    TARGETING_USER_SEGMENT: 'Segment',
    TARGETING_AD_UNIT: 'Ad Unit',
    TARGETING_ARTICLE: 'URL',
    TARGETING_SITE: 'Site',
    TARGETING_SITE_GROUP: 'Site Group',
    TARGETING_THIRD_PARTY_AUDIENCE: 'Third-Party Audiences',
    TARGETING_AD_UNIT_MATCHING_LABEL: 'Ad Unit Matching Label',
    TARGETING_RETARGETING: 'Retargeting',
    TARGETING_KEYWORD: 'Keyword',
    TARGETING_DAY_PARTING: 'Day Parting',
    TARGETING_DATE_RANGES: 'Date Ranges',
    TARGETING_DATE: 'Date',
    TARGETING_DOMAIN: 'Domain Name',
    TARGETING_DOMAIN_NAME: 'Domain Name',
    TARGETING_IP_ADDRESS: 'IP Address',
    TARGETING_VIEWABILITY: 'Viewability',
    TARGETING_AD_UNIT_VIEWABILITY: 'Viewability',
    TARGETING_PUBLISHER: 'Publisher',
    TARGETING_SEMANTIC_CATEGORY: 'Semantic',
    TARGETING_SITE_COUNTRY: 'Site Country',
    targeting: 'Targeting',
    plural: 'Targeting',
    adUnitMatchingLabel: {
      adUnitMatchingLabel: 'Ad Unit Matching Label',
      plural: 'Ad Unit Matching Labels'
    },
    adUnit: {
      adUnit: 'Ad Unit',
      plural: 'Ad Units'
    },
    thirdPartyAudience: {
      thirdPartyAudience: 'Third-Party Audience',
      plural: 'Third-Party Audiences'
    },
    category: {
      category: 'Category',
      plural: 'Categories',
      origin: {
        UPLOAD: 'Reference Data',
        CRAWLER: 'Site Content',
        SAMPLER: 'Site Content'
      }
    },
    url: {
      url: 'URL',
      plural: 'URLs'
    },
    date: {
      date: 'Date',
      plural: 'Dates',
      addTime: 'Add time',
      allDay: 'All day',
      end: 'End',
      partialDay: 'Partial day',
      removeDay: 'Remove day',
      selected: {
        one: '1 day selected',
        other: '{{n|number}} days selected'
      },
      start: 'Start',
      timezone: {
        timezone: 'Time Zone Preference',
        USER: 'User timezone',
        SYSTEM: 'Network timezone'
      }
    },
    dayParting: {
      dayParting: 'Day Parting',
      plural: 'Day Parts',
      timeZoneSetting: {
        USER: 'In end user\'s time zone',
        SYSTEM: 'In ad unit\'s time zone'
      }
    },
    define: 'Targeting',
    visibility: {
      visibility: 'Visibility',
      ABOVE_THE_FOLD: 'Above the fold',
      BELOW_THE_FOLD: 'Below the fold',
      UNKNOWN: 'Unknown'
    },
    playerSize: {
      playerSize: 'Player Size',
      SMALL_PLAYER: 'Small player',
      MEDIUM_PLAYER: 'Medium player',
      LARGE_PLAYER: 'Large player',
      UNKNOWN: 'Unknown'
    },
    semantic: {
      semantic: 'Semantic',
      plural: 'Semantic Targets'
    },
    siteCountry: {
      siteCountry: 'Site Country',
      plural: 'Site Countries'
    },
    device: {
      device: 'Device',
      plural: 'Devices',
      targetedBrands: 'Android Brands',
      HONOR: 'Honor',
      HUAWEI: 'Huawei',
      LENOVO: 'Lenovo',
      OPPO: 'Oppo',
      OTHERS: 'Others',
      REALME: 'Realme',
      SAMSUNG: 'Samsung',
      TRANSSION: 'Transsion',
      VIVO: 'Vivo',
      XIAOMI: 'Xiaomi',
      targetedOSes: 'Operating System',
      ANDROID: 'Android',
      APPLE: 'Apple (iOS, iPadOS, or MacOS)',
      LINUX: 'Linux',
      WINDOWS: 'Windows',
      targetedDeviceTypes: 'Platform',
      CONSOLE: 'Game Console',
      DESKTOP: 'Desktop',
      MOBILE: 'Smart Phone',
      READER: 'E-Reader',
      TABLET: 'Tablet',
      TV: 'Television',
      UNKNOWN: 'Unknown'
    },
    deviceBrand: {
      HONOR: 'Honor',
      HUAWEI: 'Huawei',
      LENOVO: 'Lenovo',
      OPPO: 'Oppo',
      OTHERS: 'Others',
      REALME: 'Realme',
      SAMSUNG: 'Samsung',
      TRANSSION: 'Transsion',
      VIVO: 'Vivo',
      XIAOMI: 'Xiaomi',
      targetedOSes: 'Operating System',
      ANDROID: 'Android',
      APPLE: 'Apple',
      UNKNOWN: 'Unknown'
    },
    app: {
      FACEBOOK: 'Facebook',
      INSTAGRAM: 'Instagram',
      SNAPCHAT: 'Snapchat',
      TIKTOK: 'Tiktok'
    },
    domain: {
      domain: 'Domain Name',
      plural: 'Domain Names'
    },
    geospatial: {
      geospatial: 'Geospatial Area',
      plural: 'Geospatial Areas'
    },
    inventorySource: {
      inventorySource: "Inventory Source",
      plural: "Inventory Sources"
    },
    ipAddress: {
      ipAddress: "IP Address",
      plural: "IP Addresses"
    },
    keyword: {
      keyword: 'Keyword',
      plural: 'Keywords'
    },
    keyValue: {
      keyValue: 'Key Value',
      plural: 'Key Values'
    },
    age: {
      age: 'Age',
      plural: 'Ages'
    },
    retargeting: {
      retargeting: 'Retargeting',
      plural: 'Retargeting Entries'
    },
    namedLocation: {
      namedLocation: 'Location',
      plural: 'Locations'
    },
    viewability: {
      viewability: 'Viewability',
      plural: 'Viewability'
    },
    site: {
      site: 'Site',
      plural: 'Sites'
    },
    siteGroup: {
      siteGroup: 'Site Group',
      plural: 'Site Groups'
    },
    userSegment: {
      userSegment: 'Segment',
      plural: 'Segments',
      objectState: {
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
        HIDDEN: 'Unavailable',
        UNINDEXED: 'Deleted'
      }
    },
    publisher: {
      publisher: 'Publisher',
      plural: 'Publishers'
    },
  },
  audit: {
    creative: {
      added: 'Creative <strong>{{name}}</strong> was added',
      removed: 'Creative <strong>{{name}}</strong> was removed'
    },
    targeting: {
      added: 'Targeting <strong>{{name}}</strong> was added',
      removed: 'Targeting <strong>{{name}}</strong> was removed',
      adUnit: {
        added: 'Ad Unit <strong>{{adUnitName}}</strong> was added to targeting',
        removed: 'Ad Unit <strong>{{adUnitName}}</strong> was removed from targeting'
      },
      device: {
        added: 'Device property <strong>{{deviceProperty}}</strong> was added to targeting <strong>{{name}}</strong>',
        removed: 'Device property <strong>{{deviceProperty}}</strong> was removed from targeting <strong>{{name}}</strong>'
      },
      userSegment: {
        added: 'User segment <strong>{{userSegment}}</strong> was added to targeting <strong>{{name}}</strong>',
        removed: 'User segment <strong>{{userSegment}}</strong> was removed from targeting <strong>{{name}}</strong>'
      }
    },
    'endDate.changed': 'End date was changed to <strong>{{endDate | date:"short"}}</strong>',
    objective: {
      changed: 'Objective <strong>{{objectiveType}}</strong> was changed to <strong>{{objectiveValue | number}}</strong>',
      removed: 'Objective <strong>{{objectiveType}}</strong> was removed'
    },
    'startDate.changed': 'Start date was changed to <strong>{{startDate | date:"short"}}</strong>',
    noHistory: 'There are no change events for this line item.',
    'userState.changed': 'User state was changed to <strong>{{userState}}</strong>'
  },
  browser: "Browser",
  button: {
    add: {
      add: 'Add',
      team: 'New team',
      targeting: 'New targeting',
      new: 'New',
      role: 'New role'
    },
    apply: 'Apply',
    cancel: 'Cancel',
    clear: 'Clear',
    close: 'Close',
    create: 'Create',
    delete: 'Delete',
    duplicate: 'Duplicate',
    edit: 'Edit',
    remove: 'Remove',
    save: 'Save',
    select: {
      select: 'Select',
      widgets: 'Select widgets'
    },
    signOut: 'Sign out',
    stop: 'Stop',
    today: 'Today',
    toggle: {
      select: {
        false: 'Select',
        true: 'Selected'
      }
    },
    undo: 'undo changes',
    upload: {
      asset: {
        asset: 'Upload',
        drop: 'Drop files here or click the upload button',
        dropSingle: 'Drop file here or click the upload button'
      }
    }
  },
  cdnUpload: {
    cdnUpload: 'CDN Upload',
    plural: 'CDN Uploads'
  },
  invoiceTranslation: {
    invoiceTranslation: 'Invoice Translation',
    plural: 'Invoice Translations'
  },
  emailTranslation: {
    emailTranslation: 'Email Translation',
    plural: 'Email Translations'
  },
  contextServiceConfiguration: {
    contextServiceConfiguration: 'Context Service Configuration',
    plural: 'Context Service Configurations',
    contextServices: {
      CXENSE: 'Cxense',
      ADNUNTIUS: 'Adnuntius'
    }
  },
  deduplication: {
    help: '<strong>Creative Delivery</strong><ul><li><strong>Open:</strong> the same creative from this line item could be delivered multiple times to fulfil an ad server request for ads.</li><li><strong>Unique (default):</strong> no creative from this line item will be delivered multiple times to fulfil an ad server request for ads.</li><li><strong>One per Line Item:</strong> a maximum of one creative from this line item can be delivered to fulfil an ad server request for ads.</li></ul>'
  },
  externalAdUnit: {
    externalAdUnit: 'External Ad Unit',
    plural: 'External Ad Units',
    adType: {
      IMAGE: 'Image only',
      TEXT: 'Text only',
      IMAGE_AND_TEXT: 'Image and text',
      HTML: 'HTML',
      FLASH: 'Flash',
      MIXED: 'Mixed (image, text, flash, HTML)',
      VIDEO: 'Video',
      AUDIO: 'Audio'
    },
    adUnit: {
      info: {
        info: 'linked external ad unit',
        plural: 'linked external ad units'
      },
      button: 'New External Ad Unit Link',
      emptySuffix: 'before it can be linked to.'
    },
    creative: {
      info: {
        info: 'targeted external ad unit',
        plural: 'targeted external ad units'
      },
      button: 'New External Ad Unit Target',
      emptySuffix: 'before it can be targeted.'
    }
  },
  frequencyCapType: {
    type: 'no capping',
    typeHOURS: 'per hour',
    typeWEEKS: 'per week',
    typeDAYS: 'per day'
  },
  object: {
    LineItem: {
      LineItem: 'Line Item',
      plural: 'Line Items'
    },
    AdUnit: {
      adUnit: 'Ad Unit',
      plural: 'Ad Units'
    },
    Site: {
      site: 'Site',
      plural: 'Sites'
    },
    Creative: {
      creative: 'Creative',
      plural: 'Creatives'
    },
    Order: {
      order: 'Order',
      plural: 'Orders'
    },
    Advertiser: {
      Advertiser: 'Advertiser',
      plural: 'Advertisers'
    },
    EarningsAccount: {
      EarningsAccount: 'Earnings Account',
      plural: 'Earnings Accounts'
    },
    CREATIVE: {
      CREATIVE: 'Creative',
      plural: 'Creatives'
    },
    LINE_ITEM: {
      LINE_ITEM: 'Line Item',
      plural: 'Line Items'
    },
    REPORT_EXECUTION: {
      REPORT_EXECUTION: 'Report',
      plural: 'Reports'
    },
    DATA_EXPORT: {
      DATA_EXPORT: 'Data Export',
      plural: 'Data Exports'
    },
    FORECAST_EXECUTION: {
      FORECAST_EXECUTION: 'Forecast',
      plural: 'Forecasts'
    },
    SEGMENT: {
      SEGMENT: 'Segment',
      plural: 'Segments'
    }
  },
  libraryCreative: {
    libraryCreative: 'Library Creative',
    plural: 'Library Creatives'
  },
  campaign: {
    campaign: 'Self-Service Campaign',
    plural: 'Self-Service Campaigns',
    adRejected: {
      explanation: 'Adjust your ad and resubmit your campaign for review to have it deliver your budgeted impressions.',
      ADULT_CONTENT: {
        name: 'Adult Content',
        explanation: 'The ad was rejected because it contains or leads to a landing page that contains adult-oriented content.'
      },
      BLANK_CONTENT: {
        name: 'Blank Content',
        explanation: 'The ad was rejected because it renders as blank. Please ensure that the required content have been provided correctly.'
      },
      MALFORMED_CLICK_THROUGH: {
        name: 'Malformed Destination URL',
        explanation: 'The ad was rejected because the click destination URL does not function properly.'
      },
      DRUG_RELATED_CONTENT: {
        name: 'Drug-Related Content',
        explanation: 'The ad was rejected because it or its landing page promotes drug-related content or products. This may include tobacco, alcohol, pharmaceutical or other legal or illegal drugs.'
      },
      WEAPON_RELATED_CONTENT: {
        name: 'Weapon-Related Content',
        explanation: 'The ad was rejected because it or its landing page promotes weapons or weapon-related content. This can include content that promotes dangerous knives, guns, gun parts and other related content.'
      },
      HATE_SPEECH_CONTENT: {
        name: 'Hate-Speech Content',
        explanation: 'The ad was rejected because it contains or leads to a landing page that contains hate speech content. This may include content promoting violence, harassment, racism, hatred and intolerance.'
      },
      LANDING_PAGE_REJECTED: {
        name: 'Landing Page Rejected',
        explanation: 'The ad was rejected because the landing page was low quality or difficult to interact with. This can include pages that disable browser navigation functions, include low value content, or lead to an email address or file, such as image, audio, video, or document, that requires an additional application to open.'
      },
      OFFENSIVE_CONTENT: {
        name: 'Offensive Content',
        explanation: 'The ad was rejected because it contains or leads to a landing page that contains offensive or inappropriate content. Ensure that your ads and landing pages only show family safe content.',
      },
      UNACCEPTABLE_VISUAL_EFFECTS: {
        name: 'Unacceptable Visual Effects',
        explanation: 'The ad was rejected because it uses unacceptable visual effects. This includes animated GIFs with a frame rate higher than 5 frames per second.'
      },
      DECEPTIVE_CONTENT: {
        name: 'Deceptive Content',
        explanation: 'The ad was rejected because it contains misleading claims or information. Examples include ads that make scientifically impossible claims, offer guaranteed results without a refund policy, or show testimonials claiming specific results without a link to third-party verification. Ads may also be rejected if the creative is designed to look like software download buttons, official system warnings, or webpage features like menus, search results, or other site content.'
      },
      GAMBLING_CONTENT: {
        name: 'Gambling Content',
        explanation: 'The ad was rejected because it contains gambling-related content.'
      },
      PROHIBITED_ELECTION_RELATED_CONTENT: {
        name: 'Prohibited Election-Related Content',
        explanation: 'The ad is rejected because it contains prohibited content related to government elections or currently elected government officeholders.'
      },
      SHOCKING_CONTENT: {
        name: 'Shocking Content',
        explanation: 'The ad was rejected because it contains shocking content such as profane language, gruesome or disgusting imagery, or promotions that are designed to shock or scare.'
      },
      MALWARE_CONTENT: {
        name: 'Malware Content',
        explanation: 'The ad was rejected because it or its landing page contains or leads to unacceptable content such as malware.'
      },
      POOR_QUALITY: {
        name: 'Poor Quality',
        explanation: 'The ad was rejected due to poor quality. Possible reasons for this can be graphics with insufficient resolution, unclear and washed-out imagery or poor legibility of content.'
      }
    },
  },
  axLineItem: {
    axLineItem: 'Marketplace Line Item',
    plural: 'Marketplace Line Items'
  },
  axReview: {
    axReview: 'Marketplace Review',
    plural: 'Marketplace Reviews'
  },
  review: {
    review: 'Self-Service Review',
    plural: 'Self-Service Reviews'
  },
  lineItemReview: {
    lineItemReview: 'BLAHSLJADAS'
  },
  lineItemTemplate: {
    lineItemTemplate: 'Line Item Template',
    plural: 'Line Item Templates'
  },
  lineItem: {
    rejectedReason: {
      'CREATIVE_REJECTED': 'Creative is rejected',
      'BUDGET_REJECTED': 'Budget is rejected',
      'BID_TOO_LOW': 'Bid is too low',
      'TARGETING_REJECTED': 'Targeting is rejected',
      'OTHER': 'Other'
    },
    bid: {
      cpm: 'CPM',
      cpc: 'CPC'
    },
    source: {
      DIRECT: 'Direct',
      SELF_SERVICE: 'Self-Service',
      MARKETPLACE: 'Marketplace',
    },
    smoothed: {
      label: "Smoothing",
      status: "Line Item uses smoothing"
    },
    companionAds: {
      checkbox: "each creative is shown at the same time across multiple ad units or not at all.",
      label: "Companion Creatives",
      status: "Line Item uses Companion Creatives"
    },
    lineItem: 'Line Item',
    cpc: {
      sentence: 'per click',
      short: 'CPC',
      long: 'cost per click',
      bracketed: '(cost per click)'
    },
    cpmFees: {
      type: {
        MONETARY: 'Monetary',
        MULTI_CURRENCY_MONETARY: 'Monetary',
        PERCENTAGE: 'Percentage'
      }
    },
    cpm: {
      sentence: 'per mille',
      short: 'CPM',
      long: 'cost per mille',
      bracketed: '(cost per mille)'
    },
    cpa: {
      sentence: 'per action',
      short: 'CPA',
      long: 'cost per action',
      bracketed: '(cost per action)'
    },
    dealId: {
      label: 'Deal ID'
    },
    delivery: {
      UNDER: "Under-Delivery: {{percentage}}%",
      HEALTHY: "Healthy: {{percentage}}%",
      NO_END_DATE: 'No End Date',
      UNAVAILABLE: 'Unavailable',
      NOT_APPLICABLE: 'Not Applicable',
      HEALTHY_ON_TARGET: "Healthy",
      OVER: "Over-Delivery: {{percentage}}%"
    },
    deliveryCurve: {
      FAST_AS_POSSIBLE: "Unsmoothed",
      EVEN: "Even",
      FRONT_LOADED: "Front Loaded",
      CAPPED: "Strict Cap",
      CATCH_UP: "Catch Up",
      GREEDY: "Front Running"
    },
    deliveryPrediction: {
      UNAVAILABLE: "Unavailable",
      DELIVER: "On Target",
      UNDER_DELIVER: "Under Target",
      UNCERTAIN: "Uncertain"
    },
    plural: 'Line Items',
    targeting: {
      targeting: 'Targeting',
      help: 'Target a specific section of the your targeting.'
    },
    edit: 'Edit Line Item',
    exclusionLabels: 'Exclusion Labels',
    executionState: {
      NOT_READY: "Not deliverable",
      READY: "Ready to deliver",
      RUNNING: "Delivering",
      ENDED: "Ended",
      PAUSED_OR_RESERVED: "Paused or Reserved"
    },
    executionStateEndedReason: {
      OBJECTIVE_REACHED: "Ended: Objective Reached",
      END_DATE_PASSED: "Ended: Reached End Date",
      USER_STATE_CHANGE: "Ended",
      ORDER_OBJECTIVE_REACHED: "Ended: Order Objective Reached",
      ORDER_END_DATE_PASSED: "Ended: Order End Date Reached"
    },
    new: 'New Line Item',
    axProduct: {
      title: 'Select a Product',
      adDesignChoice: '<strong>{{count}} layouts</strong> available',
      adDesignChoiceSlim: '{{count}} layout',
      adDesignChoiceSlimPlural: '{{count}} layouts',
      cpm: '<strong>{{value}}</strong> CPM',
      cpc: '<strong>{{value}}</strong> CPC',
      minSpend: '<strong>{{value}}</strong> minimum spend'
    },
    objective: {
      objective: 'Objective',
      plural: 'Objectives',
      CLICK: {
        CLICK: 'Click',
        plural: 'Clicks',
        list: 'Clicks'
      },
      IMPRESSIONS: {
        IMPRESSIONS: "Impressions",
        list: "Imps."
      },
      BUDGET: {
        BUDGET: "Budget",
        list: "Budget",
        plural: "Budget"
      },
      IMPRESSION: {
        IMPRESSION: 'Impression',
        plural: 'Impressions',
        list: 'Imps'
      },
      VIEWABLE_IMPRESSION: {
        VIEWABLE_IMPRESSION: 'Viewable Impression',
        plural: 'Viewable Impressions',
        list: 'View. Imps'
      },
      VISIBLE_IMPRESSION: {
        VISIBLE_IMPRESSION: 'Visible Impression',
        plural: 'Visible Impressions',
        list: 'Vis. Imps'
      },
      RENDERED_IMPRESSION: {
        RENDERED_IMPRESSION: 'Rendered Impression',
        plural: 'Rendered Impressions',
        list: 'R Imps'
      },
      CONVERSION: {
        CONVERSION: 'Conversion',
        plural: 'Conversions',
        list: 'Convs'
      }
    },
    orderManagement: {
      orderManagement: 'Order Management'
    },
    rateLimit: {
      periodType: {
        HOURS: {
          HOURS: 'Hours',
          singular: 'Hour',
          period: 'Hourly'
        },
        DAYS: {
          DAYS: 'Days',
          singular: 'Day',
          period: 'Daily'
        },
        WEEKS: {
          WEEKS: 'Weeks',
          singular: 'Week',
          period: 'Weekly'
        },
        MONTHS: {
          MONTHS: 'Months',
          singular: 'Month',
          period: 'Monthly'
        },
        ALL_TIME: {
          ALL_TIME: 'Lifetime',
          singular: 'Lifetime',
          period: 'Lifetime'
        }
      },
      scope: {
        GLOBAL: {
          GLOBAL: 'Line item-wide',
          radio: 'Line item-wide: limits the delivery of this line item\'s creatives.'
        },
        PER_USER: {
          PER_USER: 'Per user',
          radio: 'Per user: limits the delivery of this line item\'s creatives to any individual user.'
        }
      },
      smoothed: {
        checkbox: 'Apply rate limit smoothly'
      },
      general: {
        title: 'Rate Limit',
        name: 'Rate Limit',
        namePlural: 'Rate Limits',
        typeField: 'Type',
        scopeField: 'Scope'
      },
      general2: {
        title: 'Capping',
        name: 'Cap',
        namePlural: 'Caps',
        typeField: 'Field',
        scopeField: 'Type'
      },
      scope2: {
        GLOBAL: {
          GLOBAL: 'Global',
          radio: 'Global: limits the delivery of this line item across the board.'
        },
        PER_USER: {
          PER_USER: 'Frequency',
          radio: 'Frequency: limits the delivery of this line item to any individual user.'
        }
      },
      smoothed2: {
        checkbox: 'Apply capping smoothly'
      },
      general3: {
        title: 'Frequency',
        name: 'Frequency Cap',
        namePlural: 'Frequency Caps',
        typeField: 'Field',
        scopeField: 'Type'
      },
      scope3: {
        GLOBAL: {
          GLOBAL: 'Global',
          radio: 'Global: limits the delivery of this line item across the board.'
        },
        PER_USER: {
          PER_USER: 'Frequency',
          radio: 'Frequency: limits the delivery of this line item to any individual user.'
        }
      },
      smoothed3: {
        checkbox: 'Apply capping smoothly'
      },
      type: {
        IMPRESSION: {
          IMPRESSION: 'Impressions',
          singular: 'Impression'
        },
        CLICK: {
          CLICK: 'Clicks',
          singular: 'Click'
        },
        VISIBLE_IMPRESSION: {
          VISIBLE_IMPRESSION: 'Visible impressions',
          singular: 'Visible impression'
        },
        VIEWABLE_IMPRESSION: {
          VIEWABLE_IMPRESSION: 'Viewable impressions',
          singular: 'Viewable impression'
        },
        RENDERED_IMPRESSION: {
          RENDERED_IMPRESSION: 'Rendered impressions',
          singular: 'Rendered impression'
        },
        CONVERSION: {
          CONVERSION: 'Conversions',
          singular: 'Conversion'
        },
        BUDGET: {
          BUDGET: 'Budget',
          singular: 'Budget'
        }
      }
    },
    userState: {
      INCOMPLETE: "Incomplete",
      PROPOSED: "Proposed",
      RESERVED: "Reserved",
      APPROVED: "Approved",
      PAUSED: "Paused",
      REJECTED: "Rejected",
      STOPPED: "Stopped",
      SUBMITTED: "Submitted",
      COMPLETED: "Completed",
      explanation: {
        PROPOSED: "Line Item is proposed to be run.",
        RESERVED: "Line Item is reserved and impressions are booked without delivery.",
        APPROVED: "Line Item runs as per normal.",
        PAUSED: "Line Item delivery is blocked but impressions remain booked.",
        REJECTED: "Line Item has been reviewed and rejected from delivery.",
        STOPPED: "Line Item delivery is blocked and booked impressions are cancelled.",
        SUBMITTED: "Line Item is submitted for review before running.",
        COMPLETED: "Completed"
      }
    },
    validation: {
      invalid: "Invalid",
      valid: "Valid"
    },
    warning: {
      creatives: 'No creatives selected',
      targeting: 'No targeting selected',
      exclusionLabels: 'No exclusion labels selected'
    },
    widget: {
      notes: {
        notes: '@:note.plural',
        help: '@:note.help'
      },
      performance: 'Performance',
      reportSchedule: 'Report Schedules',
      reportRequester: 'Reports',
      topAdUnits: 'Ad Unit Totals',
      topCreatives: 'Creatives Totals',
      topSegments: 'Top User Segments'
    }
  },
  chart: {
    statType: {
      impression: 'Impression',
      viewable: 'Viewable',
      visible: 'Visible',
      click: 'Click'
    },
    countType: {
      singular: 'Trend',
      cumulative: 'Cumulative'
    },
    yAxisOption: {
      shared: 'Shared y-axis',
      multiple: 'Multiple y-axes'
    },
    dateOption: {
      last1Hour: {
        last1Hour: 'Last hour',
        sentence: 'last hour'
      },
      last6Hours: {last6Hours: 'Last 6 hours', sentence: 'the last 6 hours'},
      last12Hours: {last12Hours: 'Last 12 hours', sentence: 'the last 12 hours'},
      last24Hours: {last24Hours: 'Last 24 hours', sentence: 'the last 24 hours'},
      last7Days: {last7Days: 'Last 7 days', sentence: 'the last 7 days'},
      last30Days: {last30Days: 'Last 30 days', sentence: 'the last 30 days'},
      thisWeek: {thisWeek: 'This week', sentence: 'this week'},
      lastWeek: {lastWeek: 'Last week', sentence: 'last week'},
      thisMonth: {thisMonth: 'This month', sentence: 'this month'},
      lastMonth: {lastMonth: 'Last month', sentence: 'last month'},
      last6Months: {last6Months: 'Last 6 months', sentence: 'last 6 months'},
      last12Months: {last12Months: 'Last 12 months', sentence: 'last 12 months'},
      thisYear: {thisYear: 'This year', sentence: 'this year'},
      lastYear: {lastYear: 'Last year', sentence: 'last year'},
      custom: 'Custom Dates',
      useDates: 'Use Line Item Dates',
      today: {today: 'Today', sentence: 'today'},
      yesterday: {yesterday: 'Yesterday', sentence: 'yesterday'}
    }
  },
  creativeSet: {
    creativeSet: 'Creative Set',
    plural: 'Creative Sets',
    userState: {
      'INCOMPLETE': 'Incomplete',
      'APPROVED': 'Approved',
      'PAUSED': 'Paused'
    }
  },
  creative: {
    creative: 'Creative',
    plural: 'Creatives',
    edit: 'Edit Creative',
    new: 'New Creative',
    preview: 'Creative preview',
    copyrightStatus: {
      UNSPECIFIED: 'Unspecified',
      ADVERTISER_CONFIRMED: 'Confirmed',
      ADVERTISER_UNCONFIRMED: 'Unconfirmed'
    },
    livePreview: {
      livePreview: 'Live preview',
      create: 'Create live preview link'
    },
    dimensionType: {
      FIXED: 'Fixed',
      FIXED_WIDTH: 'Fixed Width',
      FIXED_HEIGHT: 'Fixed Height',
      FLEXIBLE: 'Flexible'
    },
    htmlCreative: {
      ADFORM_STUDIO: 'AdForm Studio',
      GOOGLE_WEB_DESIGNER: 'Google Web Designer',
      ADOBE_EDGE_5: 'Adobe Edge',
      ADOBE_ANIMATE: 'Adobe Animate',
      APPNEXUS: 'Appnexus',
      TUMULT_HYPE_3: 'Tumult Hype',
      BANNERFLOW: 'Bannerflow'
    },
    save: 'Save Creative',
    userState: {
      REJECTED: "Rejected",
      NOT_READY: "Incomplete",
      REQUIRES_REVIEW: "Requires Review",
      APPROVED: "Approved",
      PAUSED: "Paused"
    },
    warnings: {
      noCreatives: 'This creative has no creatives.'
    }
  },
  currency: {
    AED: "AED - United Arab Emirates Dirham",
    AUD: "AUD - Australian Dollar",
    CHF: "CHF - Swiss Franc",
    DKK: "DKK - Danish Krone",
    EUR: "EUR - Euro",
    GBP: "GBP - British Pound Sterling",
    HUF: "HUF - Hungarian Forint",
    IDR: "IDR - Indonesian Rupiah",
    ISK: "ISK - Icelandic Krona",
    PHP: "PHP - Philippine Peso",
    JPY: "JPY - Japanese Yen",
    MYR: "MYR - Malaysian Ringgit",
    NOK: "NOK - Norwegian Krone",
    PLN: "PLN - Polish Zloty",
    RUB: "RUB - Russian Ruble",
    SEK: "SEK - Swedish Krona",
    SGD: "SGD - Singapore Dollar",
    THB: "THB - Thai Baht",
    TRY: "TRY - Turkish Lira",
    UAH: "UAH - Ukrainian Hryvnia",
    USD: "USD - US Dollar",
    VND: "VND - Vietnamese Dong"
  },
  customEvent: {
    customEvent: 'Custom Event',
    plural: 'Custom Events',
    type: {
      SUM: 'Sum',
      COUNT: 'Count'
    }
  },
  deviceType: "Device Type",
  publisher: {
    publisher: 'Publisher',
    plural: 'Publishers'
  },
  earningsAccount: {
    earningsAccount: 'Earnings Account',
    plural: 'Earnings Accounts'
  },
  errors: {
    api: {
      stats: 'We were unable to retrieve statistics from the API. If the problem persists, please contact support.',
      title: 'Sorry, there appears to be a problem',
      dateOption: 'We are unable to retrieve statistics from the API for the date option specified. Please choose another date option or set of dates to obtain statistical information.',
      noConnection: {
        title: "Connection Loss",
        noConnection: "<p>We've lost connection to our back-end server. If the problem persists, please contact support.</p>"
      },
      generic: "<p>We're experiencing problems interacting with our back-end system. If the problem persists, please contact support.</p>",
      unauthorised: {
        title: "Unauthorised Operation",
        unauthorised: "<p>You do not have the permissions to perform the operation you requested.</p>"
      }
    }
  },
  externalDemandSource: {
    externalDemandSource: 'External Demand Source',
    plural: 'External Demand Sources',
    partners: {
      PREBID_JS: 'Prebid'
    }
  },
  marketplaceProduct: {
    marketplaceProduct: 'Marketplace Product',
    plural: 'Marketplace Products'
  },
  product: {
    product: 'Product',
    plural: 'Products'
  },
  teamGroup: {
    teamGroup: 'Team Group',
    plural: 'Team Groups'
  },
  team: {
    team: 'Team',
    type: {
      STANDARD: 'Standard',
      SUI: 'Self-Service',
      CREATIVE_AGENCY: 'Creative Agency',
      AX_ADVERTISER: 'Marketplace Advertiser',
      AX_PUBLISHER: 'Marketplace Publisher',
    },
    plural: 'Teams',
    user: {
      network: {
        zero: 'No groups are available for this user in the current network.'
      }
    }
  },
  thirdPartyAudience: {
    thirdPartyAudience: 'Third-Party Audience',
    provider: {
      NORDIC_DATA_RESOURCES: 'Nordic Data Resources',
      UNKNOWN: ''
    }
  },
  severityLevel: {
    INFO: 'Information',
    WARNING: 'Warning',
    IMPORTANT: 'Important',
    ERROR: 'Error'
  },
  layoutExample: {
    layoutExample: 'Layout Example',
    plural: 'Layout Examples'
  },
  notificationPreferences: {
    notificationPreferences: 'Notification Preferences',
    plural: 'Notification Preferences'
  },
  language: {
    EN: 'English',
    DE: 'German'
  },
  label: {
    label: {
      label: 'Label',
      abbr: 'L.',
      plural: 'Labels'
    },
    execTime: 'Exec Time (admin only)',
    fullPage: 'Full Page Dimensions',
    isFullPage: 'Full Page',
    approvalStatus: 'Safety Status',
    priority: 'Priority',
    progress: 'Progress',
    progressSlim: 'Progress (slim)',
    progressFull: 'Progress (full)',
    deliveryPercentageYesterday: 'Delivery % (yesterday)',
    deliveryPercentageYesterdaySmall: 'Delivery % (y)',
    deliveryPercentageToday: 'Delivery % (today)',
    deliveryPercentageTodaySmall: 'Delivery % (t)',
    bids: 'Bids',
    creativeSet: 'Creative Set',
    advertiserExceptions: 'Advertiser Exceptions',
    impressionTrackingUrls: 'Imp. URLs',
    source: {
      source: 'Source',
      abbr: 'S.'
    },
    cpc: 'CPC',
    eCpm: 'eCPM',
    startDateAndTime: 'Start Date and Time',
    endDateAndTime: 'End Date and Time',
    parent: 'Parent',
    createUser: 'Create User',
    dealId: 'Deal ID',
    clickDelivery7: 'Clicks (7d)',
    clickDelivery24: 'Clicks (24h)',
    clickDelivery: 'Clicks',
    impressionDelivery7: 'Impressions (7d)',
    impressionDelivery24: 'Impressions (24h)',
    impressionDelivery: 'Impressions',
    viewableDelivery: 'Viewables',
    viewableDelivery7: 'Viewables (7d)',
    viewableDelivery24: 'Viewables (24h)',
    renderedDelivery7: 'Rendereds (7d)',
    renderedDelivery24: 'Rendereds (24h)',
    renderedDelivery: 'Rendereds',
    owner: 'Owner',
    smoothing: 'Smoothing',
    defaultTranslation: 'Language',
    URLs: 'URLs',
    coupon: 'Coupon',
    country: 'Country',
    countries: 'Countries',
    category: 'Category',
    billedAmount: 'Billed Amount',
    budgetAmount: 'Budgeted Amount',
    refundedAmount: 'Refunded Amount',
    lineItem: 'Line Item',
    validTo: 'Valid To',
    validFrom: 'Valid From',
    discount: 'Discount',
    allowedDimensions: 'Allowed Dims.',
    layouts: 'Layouts',
    components: 'Components',
    deliveryPercentage: 'Delivery %',
    budgetSpendPercentage: 'Budget %',
    elapsedTimePercentage: 'Time %',
    spendDelivery7: 'Cost (7d)',
    spendDelivery24: 'Cost (24h)',
    spendDelivery: 'Cost',
    emailType: 'Email Type',
    applications: 'Applications',
    type: 'Type',
    cpm: 'CPM',
    cpmFees: 'CPM Fees',
    defaultCpmFees: 'Default CPM Fees',
    couponCode: 'Coupon Code',
    creative: 'Creative',
    creativeInfo: 'Creative Info.',
    dates: 'Dates',
    preview: 'Preview',
    budget: 'Budget',
    selfServiceBudget: 'Budget',
    libraryCreativeOverride: 'override Library Creative value',
    libraryCreativeTargetingOverride: 'override Library Creative targeting',
    libraryCreativeDimensionsOverride: 'override Library Creative dimensions',
    bulkEditingEnable: 'enable bulk editing',
    folderId: 'Folder ID',
    floorPrice: 'Floor Price',
    exportType: 'Export Type',
    lastLogin: 'Last Sign In',
    lastPasswordChange: 'Last Password Change',
    dataForExport: 'Data to Export',
    format: 'Format',
    schedule: 'Schedule',
    compression: 'Compression',
    expiry: 'Expiry',
    fields: 'Fields',
    fees: 'Fees',
    fieldsSource: 'Data Type for Export',
    plural: 'Labels',
    actions: 'Actions',
    active: 'Active',
    add: {
      add: 'Add',
      exclusionLabels: 'Add exclusions labels',
      labels: 'Add labels',
      labelFilter: 'Add label',
      matchingLabels: 'Add matching labels',
      queryLabels: 'Select {{objectType}} via labels',
      cpmFee: 'Add CPM Fee'
    },
    application: 'Application',
    matchingStyle: 'Match Type',
    criteria: 'Criteria',
    addSegment: 'Add Segment',
    removeSegment: 'Remove Segment',
    address: 'Address',
    adOps: 'Ad Ops',
    advertiser: '@:advertiser',
    advertiserUrl: 'Url',
    allObjectives: 'Objectives',
    apiKey: 'API Key',
    avatar: 'Avatar',
    blank: '',
    cdnUrl: 'URL on CDN',
    fileSizeBytes: 'File Size',
    mimeType: 'File Type',
    change: 'Change',
    numberOfCreatives: '# Creatives',
    revenueShare: 'Revenue Share',
    viewability: 'Viewability',
    viewability7: 'Viewability (7d)',
    viewability24: 'Viewability (24h)',
    valueDelivered: 'Cost',
    clickDelivered: 'Clicks',
    clickObjective: 'Click Objective',
    publishingState: 'Publishing State',
    provider: 'Provider',
    renderedImpressionObjective: 'Rendered Impression Objective',
    renderedImpressionDelivered: 'Rendered Impressions',
    visibleImpressionObjective: 'Visible Objective',
    visibleImpressionDelivered: 'Visibles',
    viewableImpressionDelivered: 'Viewables',
    viewableImpressionObjective: 'Viewables Objective',
    vatId: 'VAT Identification Number',
    layoutCategory: 'Category',
    contact: 'Contact',
    update: 'Update',
    contextService: 'Context Service',
    layoutType: 'Layout Type',
    info: 'Info',
    copy: {
      copy: 'Copy',
      adUnit: 'Copy Ad Unit',
      role: 'Copy Role',
      lineItem: 'Copy Line Item',
      earningsAccount: 'Copy Earnings Account',
      creative: 'Copy Creative',
      site: 'Copy Site',
      reportTemplate: 'Copy Report Template',
      reportSchedule: 'Copy Report Schedule',
      order: 'Copy Order',
      include: 'Include in copy',
      name: '{{name}} (copy)',
      reportTranslation: 'Copy Report Translation',
      user: 'Copy User',
      layout: 'Copy Layout',
      layoutInclude: 'Copy Layout Include'
    },
    createTime: 'Create Time',
    updateTime: 'Update Time',
    ctr7: 'CTR (7d)',
    ctr24: 'CTR (24h)',
    ctr: 'CTR',
    currency: {
      currency: 'Currency',
      available: 'Available Currencies',
      default: 'Default Currency'
    },
    dataSource: 'Data Source',
    delivery: 'Delivery',
    deliveryCurve: 'Delivery Curve',
    description: 'Description',
    dimensions: 'Dims.',
    disabled: 'Disabled',
    displayName: 'Display Name',
    days: {
      MONDAY: 'Monday',
      TUESDAY: 'Tuesday',
      WEDNESDAY: 'Wednesday',
      THURSDAY: 'Thursday',
      FRIDAY: 'Friday',
      SATURDAY: 'Saturday',
      SUNDAY: 'Sunday'
    },
    earningsAccount: 'Earnings Account',
    email: 'Email address',
    enabled: {
      enabled: 'Enabled',
      true: '@:label.enabled',
      false: '@:label.disabled'
    },
    endDate: 'End Date',
    externalReference: '@:label.number.externalReference',
    extReference: 'Ext. Reference',
    externalPartner: 'External Partner',
    executionState: '@:label.delivery',
    executionStartTime: 'Run Date',
    service: 'Service',
    externalDemandSource: 'Ext. Demand Source',
    externalSiteId: 'Ext. Site Id',
    externalAdUnitId: 'Ext. Ad Unit Id',
    externalAdType: 'Ext. Ad Type',
    folder: 'Folder',
    folders: 'Folders',
    height: 'Height',
    help: {
      help: 'Help',
      labels: 'Use labels to associate related objects across the system.',
      exclusionLabels: 'Use exclusion labels to stop conflicting line items being shown on the same set of ad units.',
      matchingLabels: 'Use matching labels to allow line items to target a particular set of ad units.',
      queryLabels: 'Use labels to find {{objectType}} throughout the system.',
      labelFilter: 'Use a label to find specific line items'
    },
    id: 'ID',
    impressionObjective: 'Impression Objective',
    impressionDelivered: 'Impressions',
    impressionVelocity: 'Impression Velocity',
    inventoryRuleset: 'Inventory Ruleset',
    lastUsed: 'Last Used',
    layout: 'Layout',
    lineItems: '@:lineItem.plural',
    link: 'Link',
    locale: 'Locale',
    MANDATORY: 'Mandatory',
    maxDimensions: 'Max. W&times;H',
    minDimensions: 'Min. W&times;H',
    members: 'Members',
    name: {
      name: 'Name',
      display: 'Display name',
      first: 'First name',
      last: 'Surname'
    },
    network: {
      network: 'Network',
      select: 'Select Active Network'
    },
    nextExecution: 'Next Execution',
    nSelected: '{{n | number}} selected',
    nMore: '&plus; {{n | number}} more &hellip;',
    noData: 'No data is available',
    number: {
      number: 'Number',
      externalReference: 'External Reference'
    },
    objectives: 'Objectives',
    objective: 'Objectives',
    OPTIONAL: 'Optional',
    order: 'Order',
    overview: 'Overview',
    password: {
      password: 'Password',
      new: 'New password'
    },
    phone: 'Phone',
    preferences: 'Preferences',
    publisher: 'Publisher',
    publisherId: 'Publisher ID',
    publishState: 'Publish State',
    recurrence: 'Run Frequency',
    reference: 'Reference',
    reportDates: 'Report Dates',
    reportTemplate: 'Report Template',
    reportType: 'Report Type',
    roleScope: 'Role Scope',
    ruleset: 'Ruleset',
    salesperson: 'Salesperson',
    scope: 'Scope',
    scopes: 'Scopes',
    smoothed: 'Smoothed',
    targetingRulesets: 'Targeting Rulesets',
    team: 'Team',
    site: 'Site',
    siteGroup: 'Site Group',
    siteRuleset: 'Ruleset',
    siteUrl: 'Site URL',
    sizeRulesets: 'Size Rulesets',
    staticUrl: 'Latest Report URL',
    star: 'Star',
    status: 'Status',
    startDate: 'Start date',
    tagId: 'Ad Unit Tag ID',
    tag: 'Tag',
    targeting: 'Targeting',
    theme: 'Theme',
    tier: 'Tier',
    timeZone: 'Time Zone',
    url: {
      url: 'URL',
      page: 'Page URL'
    },
    username: 'Username',
    userState: '@:label.status',
    validity: 'Validity',
    validationWarnings: 'Validity',
    value: 'Max. Cost',
    valueType: 'Value Type',
    velocity: 'Progress',
    warning: {
      warning: 'Warning',
      plural: 'Warnings'
    },
    width: 'Width'
  },
  lastViewed: {
    title: 'Last Viewed Objects',
    none: 'We have no record of objects you\'ve viewed.'
  },
  lastEdited: {
    title: 'Last Edited Objects',
    none: 'We have no record of objects you\'ve edited.'
  },
  exploration: {
    devices: {
      title: 'Devices'
    },
    regions: {
      title: 'Regions'
    },
    keyValues: {
      title: 'Key-Values'
    },
    countries: {
      title: 'Countries'
    },
    categories: {
      title: 'Categories'
    },
    segments: {
      title: 'Segments'
    },
    keywords: {
      title: 'Keywords'
    },
    cities: {
      title: 'Cities'
    }
  },
  creativeType: {
    IMAGE: 'Image',
    TEXT: 'Text',
    FLASH: 'Flash',
    MIXED: 'Image and Text',
    OTHER: 'Other',
    VIDEO: 'Video',
    AUDIO: 'Audio',
    HTML: 'HTML',
    THIRD_PARTY: 'Third-Party'
  },
  layoutType: {
    IMAGE: 'IMAGE',
    TEXT: 'TEXT',
    FLASH: 'FLASH',
    MIXED: 'IMAGE and TEXT',
    OTHER: 'OTHER',
    VIDEO: 'VIDEO',
    AUDIO: 'AUDIO',
    HTML: 'HTML',
    THIRD_PARTY: 'THIRD-PARTY'
  },
  layout: {
    details: {
      hide: 'hide layout details',
      show: 'show layout details'
    },
    ASSET: 'Media',
    TEXT: 'Text',
    URL: 'URL',
    CHOICE: {
      CHOICE: 'Choice',
      display: {
        RADIO: 'Radio',
        COMBO: 'Select'
      }
    },
    fieldSummary: {
      image: {
        image: 'image',
        plural: 'images'
      },
      url: {
        url: 'URL',
        plural: 'URLs'
      },
      code: {
        code: 'code field',
        plural: 'code fields'
      },
      thirdParty: {
        thirdParty: 'third-party field',
        plural: 'third-party fields'
      },
      flash: {
        flash: 'Flash file',
        plural: 'Flash files'
      },
      video: {
        video: 'video',
        plural: 'videos'
      },
      audio: {
        audio: 'Audio file',
        plural: 'audio files'
      },
      text: {
        text: 'text field',
        plural: 'text fields'
      },
      option: {
        option: 'option',
        plural: 'options'
      },
      html: {
        html: 'HTML upload',
        plural: 'HTML uploads'
      }
    },
    type: {
      STANDARD: 'Standard',
      HTML: 'HTML',
      VAST: 'VAST',
      VAST_2: 'VAST',
      THIRD_PARTY: 'Third-Party',
      NATIVE: 'Native Ad'
    },
    layout: 'Layout',
    plural: 'Layouts'
  },
  layoutInclude: {
    details: {
      hide: 'hide layout include details',
      show: 'show layout include details'
    },
    layoutInclude: 'Layout Include',
    plural: 'Layout Includes'
  },
  emailTranslations: {
    types: {
      PASSWORD_RESET_ACCOUNT_LOCKED: 'Account locked from too many failed log-in attempts',
      PASSWORD_RESET_FORGOT_PASSWORD: 'Forgotten password',
      PASSWORD_RESET_NEW_USER: 'New user',
      PASSWORD_RESET_SIGNUP_USER: 'New Self-Service User',
      PASSWORD_RESET_SIGNUP_USER_EXISTS: 'Self-Service User Tries to Register Again',
      CREATIVE_REJECTED: 'A creative has been rejected from serving',
      LINE_ITEM_SUBMITTED: 'Line item is submitted for review',
      LINE_ITEM_REJECTED: 'A line item has been rejected',
      REPORT_COMPLETE: 'A report has been generated for a line item',
      LINE_ITEM_RUNNING: 'A line item has started delivering',
      DATA_EXPORT_HAS_FAILED: 'Data Export has failed',
      API_KEY_EXPIRING: 'An API Key is about to expire',
      API_KEY_EXPIRED: 'An API Key has expired'
    }
  },
  loading: 'Loading...',
  locale: {
    ar: 'Arabic - عربى',
    ar_JO: 'Arabic (Jordan) - العربية (الأردن)',
    ar_EG: 'Arabic (Egypt) - العربية (مصر)',
    ar_AE: 'Arabic (UAE) - العربية (الإمارات العربية المتحدة)',
    my: 'Burmese - ဗမာ',
    en: 'English',
    en_AU: 'English (Australia)',
    en_CA: 'English (Canada)',
    en_US: 'English (USA)',
    en_GB: 'English (Great Britain)',
    en_PH: 'English (Philippines)',
    es: 'Spanish - español',
    es_ES: 'Spanish (Spain) - español (españa)',
    es_AR: 'Spanish (Argentina) - español (argentina)',
    et: 'Estonian - eesti keel',
    fi: 'Finnish - Suomalainen',
    in: 'Indonesian - bahasa Indonesia',
    is: 'Icelandic - íslensku',
    de: 'German - Deutsch',
    de_DE: 'German (Germany) - Deutsch (Deutschland)',
    de_AT: 'German (Austria) - Deutsch (Österreich)',
    de_CH: 'German (Switzerland) - Deutsch (Schweiz)',
    lt: 'Lithuanian - Lietuvis',
    lb: 'Luxembourgish - Lëtzebuergesch',
    ms: 'Malay - Bahasa Melayu',
    ms_BN: 'Malay (Brunei) - Bahasa Melayu (Brunei)',
    ms_MY: 'Malay (Malaysia) - Bahasa Melayu (Malaysia)',
    nl: 'Dutch',
    nl_BE: 'Dutch (Belgium) - Nederlands (België)',
    nl_NL: 'Dutch (Netherlands) - Nederlands (Nederland)',
    no: 'Norwegian - norsk',
    sv: 'Swedish - svenska',
    sv_SE: 'Swedish (Sweden) - svenska (Sverige)',
    sv_FI: 'Swedish (Finland) - svenska (Finland)',
    pl: 'Polish - Polskie',
    pt: 'Portuguese - Português',
    pt_BR: 'Portuguese (Brazil) - Português (Brasil)',
    pt_PT: 'Portuguese (Portugal) - Português (Portugal)',
    ro: 'Romanian - Română',
    ru: 'Russian - русский',
    el: 'Greek - Ελληνικά',
    fr: 'French - français',
    da: 'Danish - Dansk',
    th: 'Thai - ไทย',
    tl: 'Tagalog - Tagalog',
    tr: 'Turkish - Türk',
    vi: 'Vietnamese - Tiếng Việt'
  },
  solo: {
    default: 'This object is visible only to you because no team is assigned to it.',
    libraryCreative: 'This Library Creative is visible only to you because no teams are assigned to it.'
  },
  matchingStyle: {
    ANY: 'meeting any',
    ALL: 'meeting all',
    CUSTOM: 'meeting a custom set'
  },
  media: {
    gif: 'GIF',
    jpg: 'JPG',
    flash: {flash: 'Flash', info: 'Flash (.swf, .flv)'},
    png: 'PNG',
    html: 'HTML',
    excel: 'Excel',
    csv: 'CSV',
    font: {
      font: 'Font',
      info: 'Font files (.woff, .ttf, .eot)'
    },
    js: {
      js: 'JavaScript',
      info: 'JavaScript (.js)'
    },
    json: {json: 'JSON', info: 'JSON (.json)'},
    txt: {txt: 'Text', info: 'Text (.txt)'},
    svg: 'SVG',
    xhtml: 'XHTML',
    zip: 'Zip',
    css: {css: 'CSS', info: 'Cascading Style Sheet (.css)'},
    mp4: 'MP4',
    webm: 'WebM',
    webp: 'WebP',
    mp3: 'Mp3',
    subtitle: {subtitle: 'Subtitle', info: 'Subtitle (.srt, .vtt)'},
    quicktime: 'QuickTime',
    hls: 'HTTP Live Streaming',
    dash: 'MPEG-DASH'
  },
  mediaPlan: {
    mediaPlan: 'Media Plan',
    plural: 'Media Plans'
  },
  message: {
    undoNOT_READY: {
      text: '{{size}} selections were marked incomplete.',
      linkText: 'Undo the marking as incomplete?'
    },
    undoAPPROVED: {
      text: '{{size}} selections were approved.',
      linkText: 'Undo the approving?'
    },
    undoPAUSED: {
      text: '{{size}} selections were paused.',
      linkText: 'Undo the pause?'
    },
    undoACTIVE: {
      text: '{{size}} selections were activated.',
      linkText: 'Undo the activation?'
    },
    undoINACTIVE: {
      text: '{{size}} selections were archived.',
      linkText: 'Undo the archiving?'
    },
    undoHIDDEN: {
      text: '{{size}} selections were deleted.',
      linkText: 'Undo the deletion?'
    }
  },
  menu: {
    advertisers: '@:advertiser.plural',
    admin: 'Administration',
    adUnits: '@:adUnit.plural',
    lineItems: '@:lineItem.plural',
    creatives: '@:creative.plural',
    diagnostics: 'Diagnostics',
    earningsAccount: '@:earningsAccount.plural',
    forecast: 'Forecasts',
    layouts: '@:layout.plural',
    layoutIncludes: '@:layoutInclude.plural',
    manage: 'Manage',
    network: '@:network',
    orders: '@:order.plural',
    reports: '@:report.plural',
    reportSchedules: '@:reportSchedule.plural',
    reportTemplates: '@:reportTemplate.plural',
    roles: '@:role.plural',
    rulesets: '@:ruleset.plural',
    sites: '@:site.plural',
    siteGroup: '@:siteGroup.plural',
    siteRulesets: '@:siteRuleset.plural',
    teams: '@:team.plural',
    teamGroups: '@:teamGroup.plural',
    tiers: '@:tier.plural',
    users: '@:user.plural',
    workspaces: '@:workspace.plural'
  },
  mobileBrand: "Mobile Brand",
  network: {
    runReports: {
      REACH_ANALYSIS: 'Allow running reach analysis',
      ADVERTISER_REPORTS: 'Allow running advertiser reports'
    },
    advancedLogging: {
      label: 'Ad Server Data Export',
      false: {
        false: 'Disabled',
        helpText: 'Provides export of Ad Server data suitable for import into external Business Intelligence and Analytics tools. Contact Adnuntius if you would like this setting changed.'
      },
      true: {
        true: 'Enabled',
        helpText: 'Ad server logs can be downloaded at sftp://{{sftpHost}}. Authenticate using your Adnuntius username and password.'
      }
    },
    rawLogTypes: {
      label: 'Raw Log Types',
      helpText: 'These Raw Log Types will be present when you log into sftp://{{sftpHost}}'
    },
    network: 'Network',
    plural: 'Networks',
    'error.must.have.groups': 'Users must be added to at least one group',
    logo: {
      report: 'Report Logo',
      ui: 'User-Interface Logo'
    },
    dmpConfig: {
      NONE: {
        NONE: 'None',
        helpText: 'No DMP is configured for this network.'
      },
      ADN_DATA: {
        ADN_DATA: 'Adnuntius Data',
        helpText: ''
      },
      ADNUNTIUS: {
        ADNUNTIUS: 'Adnuntius',
        helpText: ''
      },
      CXENSE: {
        CXENSE: 'Cxense',
        helpText: ''
      }
    },
    new: 'New Network',
    tiers: 'Network Tiers'
  },
  note: {
    note: 'Note',
    plural: 'Notes',
    help: 'Notes are just that: text entries that can be used for whatever purpose required.'
  },
  notification: {
    lineItem: {
      deleted: 'Line Item <strong>{{name}}</strong> was deleted',
      ended: {
        'end.date.passed': 'Line Item <strong>{{name}}</strong> has stopped delivering as its end date has been passed',
        'objective.reached': 'Line Item <strong>{{name}}</strong> has stopped delivering as its objective target has been reached'
      },
      'not.started': 'Line Item <strong>{{name}}</strong> has not yet started delivering impressions',
      objective: {
        changed: 'Line Item <strong>{{name}}</strong> now has {{objectiveValue | number}} {{objectiveType}} as an objective',
        reached: 'Line Item <strong>{{name}}</strong> has reached its {{objectiveType}} objective',
        removed: 'Line Item <strong>{{name}}</strong> has had {{objectiveType}} removed'
      },
      submitted: 'Line Item <strong>{{name}}</strong> is submitted',
      ready: 'Line Item <strong>{{name}}</strong> is ready to deliver impressions',
      running: 'Line Item <strong>{{name}}</strong> has started to deliver impressions',
      'stopped.running': 'Line Item <strong>{{name}}</strong> has stopped delivering impressions',
      'targets.changed': 'Line Item <strong>{{name}}</strong> has had its targeting changed'
    },
    data: {
      export: {
        failed: "Data export <strong>{{name}}</strong> has failed"
      }
    },
    'report.complete': 'Report <strong>{{name}}</strong> has completed'
  },
  deliveryMethod: {
    UI: {
      UI: 'UI',
      longer: 'UI - notifications will be sent to the UI'
    },
    EMAIL: {
      EMAIL: 'Email',
      longer: 'Email - notifications will be sent to your email address'
    }
  },
  notificationMessages: {
    LINE_ITEM_DELETED: {
      description: 'Line item deleted'
    },
    LINE_ITEM_ENDED_END_DATE_PASSED: {
      description: 'Line item end date has passed'
    },
    LINE_ITEM_ENDED_OBJECTIVE_REACHED: {
      description: 'Line item objective reached'
    },
    LINE_ITEM_NOT_STARTED: {
      description: 'Line item has not yet started delivering'
    },
    LINE_ITEM_READY: {
      description: 'Line item is ready'
    },
    LINE_ITEM_RUNNING: {
      description: 'Line item is running'
    },
    LINE_ITEM_STOPPED_RUNNING: {
      description: 'Line item stopped running'
    },
    LINE_ITEM_SUBMITTED: {
      description: 'Line item is submitted for review'
    },
    LINE_ITEM_REJECTED: {
      description: 'Line item has been rejected'
    },
    CREATIVE_REJECTED: {
      description: 'Creative has been rejected'
    },
    REPORT_COMPLETE: {
      description: 'Report has completed'
    },
    DATA_EXPORT_HAS_FAILED: {
      description: 'Data Export has failed'
    }
  },
  permissions: {
    permissions: 'Permissions',
    MANAGE_ADVERTISING: {
      MANAGE_ADVERTISING: 'Manage Advertising',
      desc: 'Create, edit and delete orders, line items and creatives.'
    },
    AX_ADVERTISER: {
      AX_ADVERTISER: 'Marketplace Advertiser',
      desc: 'Create, edit and delete orders, line items and creatives, with limited access to Advertisers'
    },
    AX_ADVERTISER_ADMIN: {
      AX_ADVERTISER_ADMIN: 'Marketplace Advertiser Admin',
      desc: 'Manages Marketplace Advertiser teams'
    },
    MANAGE_BUSINESS: {
      MANAGE_BUSINESS: 'Manage Business',
      desc: 'Create, edit and delete advertisers and earnings accounts'
    },
    MANAGE_LAYOUTS: {
      MANAGE_LAYOUTS: 'Manage Layouts',
      desc: 'Create, edit and delete layouts'
    },
    CREATIVE_AGENCY: {
      CREATIVE_AGENCY: 'Creative Agent',
      desc: 'Create, edit and delete creative sets'
    },
    MANAGE_PUBLISHING: {
      MANAGE_PUBLISHING: 'Manage Publishing',
      desc: 'Create, edit and delete sites and ad units'
    },
    AX_PUBLISHER: {
      AX_PUBLISHER: 'Marketplace Publisher',
      desc: 'Create, edit and delete sites and ad units'
    },
    AX_PUBLISHER_ADMIN: {
      AX_PUBLISHER_ADMIN: 'Marketplace Publisher Admin',
      desc: 'Manages Marketplace Publisher sites and teams'
    },
    MANAGE_REPORT_TEMPLATES: {
      MANAGE_REPORT_TEMPLATES: 'Manage Report Templates',
      desc: 'Create, edit and delete report templates'
    },
    MANAGE_SYSTEM: {
      MANAGE_SYSTEM: 'Manage System',
      desc: 'Edit the network and create, edit and delete users, tiers, custom events, roles and teams'
    },
    RUN_REPORTS: {
      RUN_REPORTS: 'Run Reports',
      desc: 'Create, edit and delete report schedules and generate reports'
    },
    SELF_SERVICE_OWN_ADVERTISING: {
      SELF_SERVICE_OWN_ADVERTISING: 'Self-Service Own Advertising',
      desc: 'User has limited set of rights to perform advertising functions and have that unavailable to others.'
    },
    DOWNLOAD_LOGS: {
      DOWNLOAD_LOGS: 'Download Logs',
      desc: 'Permission to download our raw ad server logs from our SFTP server.'
    },
    VIEW_ALL_OBJECTS: {
      VIEW_ALL_OBJECTS: 'View All Objects',
      desc: 'Can view all objects within the system, including those without a team or found within teams the user does not belong to.'
    },
    SELF_SERVICE_USER_ADMIN: {
      SELF_SERVICE_USER_ADMIN: 'Self-Service User Admin',
      desc: 'Allows the creation self service users.'
    },
    MANAGE_NETWORK_DASHBOARDS: {
      MANAGE_NETWORK_DASHBOARDS: 'Manage Dashboards',
      desc: 'Create, edit and delete network dashboards.'
    },
    DATA_ADMIN: {
      DATA_ADMIN: 'Adnuntius Data Admin',
      desc: 'Manages the main account in Adnuntius Data.'
    },
    MANAGE_DATA: {
      MANAGE_DATA: 'Manage Adnuntius Data',
      desc: 'Permitted to create segments and folders in Adnuntius Data.'
    }
  },
  operatingSystem: "Operating System",
  folio: {
    folio: 'Folio',
    plural: 'Folios',
    new: 'New Folio'
  },
  order: {
    order: 'Order',
    plural: 'Orders',
    new: 'New Order',
    smoothed: {
      checkbox: "deliver order's line items evenly over time",
      label: "Smoothing",
      status: "Order uses smoothing"
    },
  },
  reportTemplate: {
    reportTemplate: 'Report Template',
    plural: 'Report Templates',
    header: 'Report Header',
    new: 'New',
    newMenuHeading: 'Create new report template',
    option: {
      requests: '@:report.field.requests',
      positiveRequests: '@:report.field.positiveRequests',
      matchRate: '@:report.field.matchRate',
      clicks: '@:report.field.clicks',
      viewables: '@:report.field.viewables',
      visibles: '@:report.field.visibles',
      ctr: '@:report.field.ctr',
      visibility: '@:report.field.visibility',
      viewability: '@:report.field.viewability',
      measurableImpressions: '@:report.field.measurableImpressions',
      measurableRendered: '@:report.field.measurableRendered',
      date: '@:report.field.date',
      description: '@:report.field.description',
      earnings: '@:report.field.earnings',
      revenueShare: '@:report.field.revenueShare',
      cost: '@:report.field.cost',
      fee: '@:report.field.fee',
      techFee: '@:report.field.techFee',
      billedAmount: '@:report.field.billedAmount',
      impressions: '@:report.field.impressions',
      rendered: '@:report.field.rendered',
      limit: '# Results',
      name: '@:report.field.name',
      uniqueUsers: '@:report.field.uniqueUsers',
      order: '@:report.field.order',
      lineItem: 'Line Item',
      externalId: 'ID',
      advertiser: '@:report.field.advertiser',
      vatId: '@:report.field.vatId',
      eCpm: '@:report.field.eCpm',
      vCpm: '@:report.field.vCpm',
      customStats: '@:report.field.customStats',
      customEvents: '@:report.field.customEvents',
      customEventsAverages: '@:report.field.customEventsAverages',
      creationDate: '@:report.field.creationDate',
      startDate: '@:report.field.startDate',
      endDate: '@:report.field.endDate',
    },
    component: {
      component: 'Report Component',
      plural: 'Report Components',
      lineitemdescriptionreportcomponentlineitem: {
        lineitemdescriptionreportcomponentlineitem: 'Line Item Description',
        description: 'Line Item and advertiser name and line item flight dates'
      },
      lineitemtotalsreportcomponentlineitem: {
        lineitemtotalsreportcomponentlineitem: 'Line Item Totals',
        description: 'Line Item statistical totals with graphics'
      },
      dailybreakdownreportcomponentlineitem: {
        dailybreakdownreportcomponentlineitem: 'Daily Breakdown',
        description: 'Configurable line item statistics presented in table form with daily breakdown'
      },
      dailybreakdownchartreportcomponentlineitem: {
        dailybreakdownchartreportcomponentlineitem: 'Daily Breakdown Chart',
        description: 'Chart of line item performance data over time'
      },
      textreportcomponentlineitem: {
        textreportcomponentlineitem: 'Text',
        description: 'Add your own styled text to your report.'
      },
      sitebreakdownreportcomponentearningsaccount: {
        sitebreakdownreportcomponentearningsaccount: 'Site Breakdown',
        description: 'Daily breakdown of performance for Sites',
      },
      orderbreakdownreportcomponentadvertiser: {
        orderbreakdownreportcomponentadvertiser: 'Order Breakdown',
        description: 'Daily breakdown of performance for all of the Advertiser\'s Orders',
      },
      creativedailybreakdownreportcomponentlineitem: {
        creativedailybreakdownreportcomponentlineitem: 'Daily Creative Breakdown',
        description: 'Daily breakdown of performance for all of the line item\'s creatives'
      },
      topcreativesreportcomponentlineitem: {
        topcreativesreportcomponentlineitem: 'Creatives',
        description: 'Configurable number of top performing creatives with performance data'
      },
      toplocationsreportcomponentlineitem: {
        toplocationsreportcomponentlineitem: 'Top Locations',
        description: 'Configurable number of top performing locations with performance data'
      },
      topadunitsreportcomponentlineitem: {
        topadunitsreportcomponentlineitem: 'Ad Units',
        description: 'Configurable number of top performing ad units with performance data'
      },
      topmobilebrandsreportcomponentlineitem: {
        topmobilebrandsreportcomponentlineitem: 'Top Android Brands',
        description: 'Configurable number of top performing android brands with performance data'
      },
      topoperatingsystemsreportcomponentlineitem: {
        topoperatingsystemsreportcomponentlineitem: 'Top Operating Systems',
        description: 'Configurable number of top performing operating systems with performance data'
      },
      topkeyvaluesreportcomponentlineitem: {
        topkeyvaluesreportcomponentlineitem: 'Top Key Values',
        description: 'Configurable number of top performing key value targets with performance data'
      },
      topcategoriesreportcomponentlineitem: {
        topcategoriesreportcomponentlineitem: 'Top Categories',
        description: 'Configurable number of top performing category targets with performance data'
      },
      topdevicetypesreportcomponentlineitem: {
        topdevicetypesreportcomponentlineitem: 'Top Device Types',
        description: 'Configurable number of top performing device types with performance data'
      },
      topbrowsersreportcomponentlineitem: {
        topbrowsersreportcomponentlineitem: 'Top Browsers',
        description: 'Configurable number of top performing browsers with performance data'
      },
      topusersegmentsreportcomponentlineitem: {
        topusersegmentsreportcomponentlineitem: 'Top User Segments',
        description: 'Configurable number of top performing user segments with performance data'
      },
      targetingbreakdownreportcomponentlineitem: {
        targetingbreakdownreportcomponentlineitem: 'Targeting Breakdown',
        description: 'Stats on what targeting delivered impressions.'
      },
      lineitemdescriptionreportcomponentcreativeset: {
        lineitemdescriptionreportcomponentcreativeset: 'Creative Set Description',
        description: 'Creative Set and advertiser name and Creative Set flight dates'
      },
      lineitemtotalsreportcomponentcreativeset: {
        lineitemtotalsreportcomponentcreativeset: 'Creative Set Totals',
        description: 'Creative Set statistical totals with graphics'
      },
      dailybreakdownreportcomponentcreativeset: {
        dailybreakdownreportcomponentcreativeset: 'Daily Breakdown',
        description: 'Configurable Creative Set statistics presented in table form with daily breakdown'
      },
      dailybreakdownchartreportcomponentcreativeset: {
        dailybreakdownchartreportcomponentcreativeset: 'Daily Breakdown Chart',
        description: 'Chart of Creative Set performance data over time'
      },
      textreportcomponentcreativeset: {
        textreportcomponentcreativeset: 'Text',
        description: 'Add your own styled text to your report.'
      },
      creativepreviewreportcomponentlineitemlist: {
        creativepreviewreportcomponentlineitemlist: 'Creative Previews',
        description: 'Show previews of the creatives'
      },
      creativepreviewreportcomponentlineitem: {
        creativepreviewreportcomponentlineitem: 'Creative Previews',
        description: 'Show previews of the creatives that were delivered'
      },
      topcreativesreportcomponentcreativeset: {
        topcreativesreportcomponentcreativeset: 'Creatives',
        description: 'Configurable number of top performing creatives with performance data'
      },
      toplocationsreportcomponentcreativeset: {
        toplocationsreportcomponentcreativeset: 'Top Locations',
        description: 'Configurable number of top performing locations with performance data'
      },
      topadunitsreportcomponentcreativeset: {
        topadunitsreportcomponentcreativeset: 'Ad Units',
        description: 'Configurable number of top performing ad units with performance data'
      },
      topmobilebrandsreportcomponentcreativeset: {
        topmobilebrandsreportcomponentcreativeset: 'Top Android Brands',
        description: 'Configurable number of top performing android brands with performance data'
      },
      topoperatingsystemsreportcomponentcreativeset: {
        topoperatingsystemsreportcomponentcreativeset: 'Top Operating Systems',
        description: 'Configurable number of top performing operating systems with performance data'
      },
      topkeyvaluesreportcomponentcreativeset: {
        topkeyvaluesreportcomponentcreativeset: 'Top Key Values',
        description: 'Configurable number of top performing key value targets with performance data'
      },
      topcategoriesreportcomponentcreativeset: {
        topcategoriesreportcomponentcreativeset: 'Top Categories',
        description: 'Configurable number of top performing category targets with performance data'
      },
      topdevicetypesreportcomponentcreativeset: {
        topdevicetypesreportcomponentcreativeset: 'Top Device Types',
        description: 'Configurable number of top performing device types with performance data'
      },
      topbrowsersreportcomponentcreativeset: {
        topbrowsersreportcomponentcreativeset: 'Top Browsers',
        description: 'Configurable number of top performing browsers with performance data'
      },
      topusersegmentsreportcomponentcreativeset: {
        topusersegmentsreportcomponentcreativeset: 'Top User Segments',
        description: 'Configurable number of top performing user segments with performance data'
      },
      targetingbreakdownreportcomponentcreativeset: {
        targetingbreakdownreportcomponentcreativeset: 'Targeting Breakdown',
        description: 'Stats on what targeting delivered impressions.'
      },
      textreportcomponentlineitemlist: {
        textreportcomponentlineitemlist: 'Text',
        description: 'Add your own styled text to your report.'
      },
      textreportcomponentsalesperson: {
        textreportcomponentsalesperson: 'Text',
        description: 'Add your own styled text to your report.'
      },
      textreportcomponentadvertiser: {
        textreportcomponentadvertiser: 'Text',
        description: 'Add your own styled text to your report.'
      },
      textreportcomponentadunit: {
        textreportcomponentadunit: 'Text',
        description: 'Add your own styled text to your report.'
      },
      textreportcomponentsite: {
        textreportcomponentsite: 'Text',
        description: 'Add your own styled text to your report.'
      },
      textreportcomponentsitegroup: {
        textreportcomponentsitegroup: 'Text',
        description: 'Add your own styled text to your report.'
      },
      dailybreakdownreportcomponentadvertiser: {
        dailybreakdownreportcomponentadvertiser: 'Daily Breakdown',
        description: 'Configurable line item statistics presented in table form with daily breakdown'
      },
      dailybreakdownchartreportcomponentadvertiser: {
        dailybreakdownchartreportcomponentadvertiser: 'Daily Breakdown Chart',
        description: 'Chart of line item performance data over time'
      },
      dailybreakdownreportcomponentlineitemlist: {
        dailybreakdownreportcomponentlineitemlist: '@:reportTemplate.component.dailybreakdownreportcomponentlineitem',
        description: '@:reportTemplate.component.dailybreakdownreportcomponentlineitem.description'
      },
      dailybreakdownchartreportcomponentlineitemlist: {
        dailybreakdownchartreportcomponentlineitemlist: '@:reportTemplate.component.dailybreakdownchartreportcomponentlineitem',
        description: '@:reportTemplate.component.dailybreakdownchartreportcomponentlineitem.description'
      },
      lineitembreakdownreportcomponentlineitemlist: {
        lineitembreakdownreportcomponentlineitemlist: 'Line Item Breakdown',
        description: 'Configurable line item statistics presented in tabular form'
      },
      toplocationsreportcomponentlineitemlist: {
        toplocationsreportcomponentlineitemlist: 'Top Locations',
        description: 'Configurable number of top performing locations with performance data'
      },
      lineitembreakdownreportcomponentadvertiser: {
        lineitembreakdownreportcomponentadvertiser: 'Line Item Breakdown',
        description: 'Configurable line item statistics presented in tabular form'
      },
      creativebreakdownreportcomponentadvertiser: {
        creativebreakdownreportcomponentadvertiser: 'Creative Breakdown',
        description: 'Configurable creative statistics presented in tabular form'
      },
      creativebreakdownreportcomponentlineitemlist: {
        creativebreakdownreportcomponentlineitemlist: 'Creative Breakdown',
        description: 'Configurable creative statistics presented in tabular form'
      },
      orderdescriptionreportcomponentlineitemlist: {
        orderdescriptionreportcomponentlineitemlist: 'Order Description',
        description: 'Order and advertiser name and line item flight dates'
      },
      advertiserdescriptionreportcomponentadvertiser: {
        advertiserdescriptionreportcomponentadvertiser: 'Advertiser Description',
        description: 'Advertiser name and information'
      },
      networkdescriptionreportcomponentnetwork: {
        networkdescriptionreportcomponentnetwork: 'Network Description',
        description: 'Information about the network this report runs against'
      },
      networktotalsreportcomponentnetwork: {
        networktotalsreportcomponentnetwork: 'Network Totals',
        description: 'Stats totals across the network presented as final sums and in a chart over time'
      },
      networkbreakdownreportcomponentnetwork: {
        networkbreakdownreportcomponentnetwork: 'Network Breakdown',
        description: 'Daily breakdown of stats across the network'
      },
      adunitdescriptionreportcomponentadunit: {
        adunitdescriptionreportcomponentadunit: 'Ad Unit Description',
        description: 'Ad unit information.'
      },
      publishingtotalsreportcomponentadunit: {
        publishingtotalsreportcomponentadunit: 'Ad Unit Totals',
        description: 'Ad Unit statistical totals with graphics'
      },
      publishingtotalsreportcomponentsite: {
        publishingtotalsreportcomponentsite: 'Site Totals',
        description: 'Site statistical totals with graphics'
      },
      publishingtotalsreportcomponentsitegroup: {
        publishingtotalsreportcomponentsitegroup: 'Site Group Totals',
        description: 'Site Group statistical totals with graphics'
      },
      publishingtotalsreportcomponentearningsaccount: {
        publishingtotalsreportcomponentearningsaccount: 'Totals',
        description: 'Statistical totals with graphics'
      },
      targetingbreakdownreportcomponentadunit: {
        targetingbreakdownreportcomponentadunit: 'Ad Unit Targeting Breakdown',
        description: 'Stats on what targeting delivered impressions.'
      },
      dailybreakdownreportcomponentadunit: {
        dailybreakdownreportcomponentadunit: '@:reportTemplate.component.dailybreakdownreportcomponentlineitem',
        description: 'Configurable ad unit statistics presented in tabular form with a daily breakdown'
      },
      dailybreakdownchartreportcomponentadunit: {
        dailybreakdownchartreportcomponentadunit: '@:reportTemplate.component.dailybreakdownchartreportcomponentlineitem',
        description: 'Chart of ad unit performance data with over time'
      },
      ordertotalsreportcomponentlineitemlist: {
        ordertotalsreportcomponentlineitemlist: 'Order Totals',
        description: 'Order statistical totals with graphics'
      },
      advertisertotalsreportcomponentadvertiser: {
        advertisertotalsreportcomponentadvertiser: 'Advertiser Totals',
        description: 'Advertiser statistical totals with graphics'
      },
      advertisingtotalsreportcomponentsalesperson: {
        advertisingtotalsreportcomponentsalesperson: "Advertising Totals",
        description: 'Totals relating to the line items the salesperson is assigned.'
      },
      salespersondescriptionreportcomponentsalesperson: {
        salespersondescriptionreportcomponentsalesperson: 'Salesperson Description',
        description: 'Description of the salesperson.'
      },
      lineitembreakdownreportcomponentsalesperson: {
        lineitembreakdownreportcomponentsalesperson: "Line Item Breakdown",
        description: 'Breakdown of statistical information for the line items the salesperson is assigned.'
      },
      creativebreakdownreportcomponentsalesperson: {
        creativebreakdownreportcomponentsalesperson: "Creative Breakdown",
        description: 'Breakdown of statistical information for the creatives the salesperson is assigned.'
      },
      dailybreakdownreportcomponentsalesperson: {
        dailybreakdownreportcomponentsalesperson: "Daily Breakdown",
        description: 'Configurable line item statistics presented in table form with daily breakdown'
      },
      dailybreakdownchartreportcomponentsalesperson: {
        dailybreakdownchartreportcomponentsalesperson: "Daily Breakdown Chart",
        description: 'Chart of line item performance data over time'
      },
      adunitlistdescriptionreportcomponentsite: {
        adunitlistdescriptionreportcomponentsite: "Site Description",
        description: "Description of site"
      },
      adunitbreakdownreportcomponentsite: {
        adunitbreakdownreportcomponentsite: "Ad Unit Breakdown",
        description: 'Configurable ad unit statistics presented in tabular form'
      },
      dailybreakdownreportcomponentsite: {
        dailybreakdownreportcomponentsite: "Daily Breakdown",
        description: 'Configurable statistics presented in table form with daily breakdown'
      },
      dailybreakdownchartreportcomponentsite: {
        dailybreakdownchartreportcomponentsite: "Daily Breakdown Chart",
        description: 'Chart of ad unit performance data over time'
      },
      adunitlistdescriptionreportcomponentsitegroup: {
        adunitlistdescriptionreportcomponentsitegroup: "Site Group Description",
        description: 'Description of Site Group'
      },
      adunitbreakdownreportcomponentsitegroup: {
        adunitbreakdownreportcomponentsitegroup: "Ad Unit Breakdown",
        description: '@:reportTemplate.component.dailybreakdownchartreportcomponentsite.description'
      },
      dailybreakdownreportcomponentsitegroup: {
        dailybreakdownreportcomponentsitegroup: "Daily Breakdown",
        description: '@:reportTemplate.component.dailybreakdownreportcomponentsite.description'
      },
      dailybreakdownchartreportcomponentsitegroup: {
        dailybreakdownchartreportcomponentsitegroup: "Daily Breakdown Chart",
        description: '@:reportTemplate.component.dailybreakdownchartreportcomponentsite.description'
      },
      adunitlistdescriptionreportcomponentearningsaccount: {
        adunitlistdescriptionreportcomponentearningsaccount: "Description",
        description: 'General description'
      },
      adunitbreakdownreportcomponentearningsaccount: {
        adunitbreakdownreportcomponentearningsaccount: "Ad Unit Breakdown",
        description: '@:reportTemplate.component.dailybreakdownchartreportcomponentsite.description'
      },
      dailybreakdownreportcomponentearningsaccount: {
        dailybreakdownreportcomponentearningsaccount: "Daily Breakdown",
        description: '@:reportTemplate.component.dailybreakdownreportcomponentsite.description'
      },
      dailybreakdownchartreportcomponentearningsaccount: {
        dailybreakdownchartreportcomponentearningsaccount: "Daily Breakdown Chart",
        description: '@:reportTemplate.component.dailybreakdownchartreportcomponentsite.description'
      },
      textreportcomponentnetwork: {
        textreportcomponentnetwork: 'Text',
        description: 'Add your own styled text to your report.'
      },
      textreportcomponentearningsaccount: {
        textreportcomponentearningsaccount: 'Text',
        description: 'Add your own styled text to your report.'
      },
      campaigndescriptionreportcomponentselfservice: {
        campaigndescriptionreportcomponentselfservice: 'Campaign Description',
        description: 'Description of campaign'
      },
      campaigntotalsreportcomponentselfservice: {
        campaigntotalsreportcomponentselfservice: 'Campaign Totals',
        description: 'Totals relating to the self-service advertier\'s campaign'
      },
      dailybreakdownreportcomponentselfservice: {
        dailybreakdownreportcomponentselfservice: 'Daily Breakdown',
        description: 'Configurable daily campaign statistics presented in tabular form'
      },
      dailybreakdownchartreportcomponentselfservice: {
        dailybreakdownchartreportcomponentselfservice: 'Daily Breakdown Chart',
        description: 'Chart of campaign performance data over time'
      }
    }
  },
  invoice: {
    translationAdnuntiusOrderId: 'Adnuntius Order ID',
    translationPayPalOrderId: 'PayPal Order ID',
    translationOrderTotal: 'Order Total',
    translationOrderItem: 'Order Item',
    translationOrderPrice: 'Order Price',
    translationOrderDetails: 'Order Details',
    translationDiscountTitle: 'Discount Title',
    translationDiscountAmount: 'Discount Amount',
    translationTransactionAmount: 'Transaction Amount',
    translationTransactionDate: 'Transaction Date',
    translationTransactionActivity: 'Transaction Activity',
    translationTransactionDescription: 'Transaction Description',
    translationTransactionPaymentReceived: 'Transaction Payment Received',
    translationTransactionRefundAmount: 'Refund Amount',
    translationTransactionRefund: 'Transaction Refund',
    translationReceiptTitle: 'Receipt Title',
    translationCompanyAddressLine1: 'Company Address Line 1',
    translationCompanyAddressLine2: 'Company Address Line 2',
    translationCompanyAddressLine3: 'Company Address Line 3',
    translationCompanyAddressLine4: 'Company Address Line 4',
    translationCampaignEndedEarly: 'Refund: campaign ended early',
    translationCampaignUnderDelivered: 'Refund: campaign underdelivered'
  },
  report: {
    report: 'Report',
    plural: 'Reports',
    generate: 'Generate report',
    history: {
      history: 'Report History',
      view: 'View report history'
    },
    auditHistory: {
      auditHistory: 'Audit History',
      help: 'Lists a history of significant changes, including the change timestamps and users.'
    },
    field: {
      addedMembers: {
        addedMembers: 'Added Members',
        abbr: 'A M.'
      },
      removedMembers: {
        removedMembers: 'Removed Members',
        abbr: 'R M.'
      },
      totalMembers: {
        totalMembers: 'Members',
        abbr: 'M.'
      },
      date: 'Date',
      bids: {
        bids: 'Available Traffic',
        abbr: 'Av.'
      },
      belowFloor: {
        belowFloor: 'Bid Below Floor Price',
        abbr: 'B.F.'
      },
      cpc: 'CPC',
      losingBids: {
        losingBids: 'Beaten in Auction',
        abbr: 'B.A.'
      },
      frequencyCapped: {
        frequencyCapped: 'Frequency Capped',
        abbr: 'F.C.'
      },
      rateLimited: {
        rateLimited: 'Smoothed',
        abbr: 'S.'
      },
      clicks: 'Clicks',
      conversions: {
        conversions: 'Conversions',
        abbr: 'Conv.'
      },
      labels: 'Labels',
      errors: {
        errors: 'Errors',
        abbr: 'E.'
      },
      techFee: {
        techFee: 'Tech Fee',
        abbr: 'T F.'
      },
      creativeFormatFee: {
        creativeFormatFee: 'Creative Format Fee',
        abbr: 'C F F.'
      },
      creativeTagFee: {
        creativeTagFee: 'Creative Tag Fee',
        abbr: 'C T F.'
      },
      dataFee: {
        dataFee: 'Data Fee',
        abbr: 'D F.'
      },
      supplementaryFee: {
        supplementaryFee: 'Supplementary Fee',
        abbr: 'S F.'
      },
      fee: {
        fee: 'Fees',
        abbr: 'F.'
      },
      winningBid: {
        winningBid: 'Winning Bids',
        abbr: 'W B.'
      },
      highestWinningCPM: {
        highestWinningCPM: 'Highest Winning CPM',
        abbr: 'H W C.'
      },
      impressionRequests: {
        impressionRequests: 'Requests',
        abbr: 'R.'
      },
      impressionsPerUniqueUser: {
        impressionsPerUniqueUser: 'Imps. per Unique',
        abbr: 'I U.'
      },
      losingBid: {
        losingBid: 'Losing Bids',
        abbr: 'L B.'
      },
      losses: {
        losses: 'Losses',
        abbr: 'L.'
      },
      noBids: {
        noBids: 'No Bids',
        abbr: 'N B.'
      },
      passbacks: {
        passbacks: 'Passbacks',
        abbr: 'PB.'
      },
      pageViews: {
        pageViews: 'Page Views',
        abbr: 'PV.'
      },
      profileUpdates: {
        profileUpdates: 'Profile Updates',
        abbr: 'PU.'
      },
      uniqueProfiles: {
        uniqueProfiles: 'Unique User Profiles',
        abbr: 'UP.'
      },
      viewables: {
        viewables: 'Viewables',
        abbr: 'V.'
      },
      visibles: {
        visibles: 'Visibles',
        abbr: 'Vis.'
      },
      visibility: {
        visibility: 'Visibility',
        abbr: 'Vis. %'
      },
      viewability: {
        viewability: 'Viewability',
        abbr: 'V. %'
      },
      impressions: {
        impressions: 'Impressions',
        abbr: 'Imp.'
      },
      rendered: {
        rendered: 'Rendered Impressions',
        abbr: 'R Imp.'
      },
      renderedRate: {
        renderedRate: 'Rendered Rate',
        abbr: 'R R.'
      },
      uniqueUsers: {
        uniqueUsers: 'Uniques',
        abbr: 'Uniq.'
      },
      measurableImpressions: {
        measurableImpressions: 'Measurable Impressions',
        abbr: 'MImp.'
      },
      measurableRendered: {
        measurableRendered: 'Measurable Rendereds',
        abbr: 'M R Imp.'
      },
      requests: {
        requests: 'Requests',
        abbr: 'R.'
      },
      timeouts: {
        timeouts: 'Timeouts',
        abbr: 'TO.'
      },
      wins: {
        wins: 'Wins',
        abbr: 'W'
      },
      ctr: 'CTR',
      cost: 'Cost',
      totalCost: {
        totalCost: 'Total Cost',
        abbr: 'T.C.'
      },
      earnings: {
        earnings: 'Earnings',
        abbr: 'E.'
      },
      billedAmount: 'Billed Amount',
      vatId: 'VAT Identification Number',
      creationDate: 'Creation Date',
      startDate: {
        startDate: 'Start Date',
        abbr: 'S.D.'
      },
      deviceStorageAllowed: {
        deviceStorageAllowed: 'Cookies Permitted',
        abbr: 'C.P.'
      },
      endDate: {
        endDate: 'End Date',
        abbr: 'E.D.'
      },
      eCpm: 'eCPM',
      vCpm: 'vCPM',
      positiveImpressionRequests: {
        positiveImpressionRequests: 'Positive Requests',
        abbr: 'P. R.'
      },
      revenueShare: {
        revenueShare: 'Revenue Share',
        abbr: 'R. S.'
      },
      positiveRequests: 'Positive Requests',
      internalBidsRejected: {
        internalBidsRejected: 'Rejected Bids',
        abbr: 'R. B.'
      },
      name: 'Name',
      order: 'Order',
      matchRate: {matchRate: 'Match Rate', abbr: 'M. R.'},
      matchScore: {
        matchScore: 'Match Score',
        abbr: 'M. S.'
      },
      advertiser: 'Advertiser',
      customStats: 'Custom Stats',
      customEvents: 'Custom Events',
      customEventsAverages: 'Custom Events Averages'
    },
    status: {
      PENDING: 'Pending',
      PROCESSING: 'Processing',
      COMPLETED: 'Completed',
      REJECTED: 'Rejected',
      FAILED: 'Failed',
      CANCELLED: 'Cancelled',
      QUEUED: 'Queued',
      TIMED_OUT: 'Timed Out'
    },
    reportType: {
      AD_UNIT: {
        AD_UNIT: 'Ad Unit Report',
        plural: 'Ad Unit Reports',
        simple: 'Ad Unit',
        explanation: 'A report template for reporting on ad units'
      },
      CREATIVE_SET: {
        CREATIVE_SET: 'Creative Set Report',
        plural: 'Creative Set Reports',
        simple: 'Creative Set',
        explanation: 'A report template for reporting on creative sets'
      },
      LINE_ITEM: {
        LINE_ITEM: 'Line Item Report',
        plural: 'Line Item Reports',
        simple: 'Line Item',
        explanation: 'A report template for reporting on line items'
      },
      LINE_ITEM_LIST: {
        LINE_ITEM_LIST: 'Order / Line Item List Report',
        plural: 'Order / Line Item List Reports',
        simple: 'Order / Line Item List',
        explanation: 'A report template for reporting on orders or lists of line items'
      },
      SALESPERSON: {
        SALESPERSON: 'Salesperson Report',
        plural: 'Salesperson Reports',
        simple: 'Salesperson',
        explanation: 'A report template for reporting on the orders a salesperson is responsible for'
      },
      ADVERTISER: {
        ADVERTISER: 'Advertiser Report',
        plural: 'Advertiser Reports',
        simple: 'Advertiser',
        explanation: 'A report template for reporting on advertisers'
      },
      NETWORK: {
        NETWORK: 'Network Report',
        plural: 'Network Reports',
        simple: 'Network',
        explanation: 'A report template for reporting on your entire network'
      },
      SITE: {
        SITE: 'Site Report',
        plural: 'Site Reports',
        simple: 'Site',
        explanation: 'A report template for reporting on sites'
      },
      SITE_GROUP: {
        SITE_GROUP: 'Site Group Report',
        plural: 'Site Group Reports',
        simple: 'Site Group',
        explanation: 'A report template for reporting on site groups'
      },
      EARNINGS_ACCOUNT: {
        EARNINGS_ACCOUNT: 'Earnings Account Report',
        plural: 'Earnings Account Reports',
        simple: 'Earnings Account',
        explanation: 'A report template for reporting on earnings accounts'
      },
      SELF_SERVICE: {
        SELF_SERVICE: 'Self-Service Report',
        plural: 'Self-Service Reports',
        simple: 'Self Service',
        explanation: 'A report template for self-service advertisers to see their campaign data'
      }
    }
  },
  reportTranslation: {
    reportTranslation: 'Report Translation',
    plural: 'Report Translations'
  },
  reportSchedule: {
    reportSchedule: 'Report Schedule',
    plural: 'Report Schedules',
    period: {
      RECURRENCE_PERIOD: {
        RECURRENCE_PERIOD: 'from the last report\'s execution date',
        radio: 'Moving: report on the period from the last report\'s execution to the next'
      },
      FLIGHT: {
        FLIGHT: 'from the object\'s start date',
        radio: 'Fixed: use the object\'s start and end dates'
      },
      DAILY: {
        DAILY: 'on the previous day\'s events',
        radio: 'Daily: report on the previous day\'s events'
      },
      WEEKLY: {
        WEEKLY: 'on the last seven days\' events',
        radio: 'Weekly: report on the last seven days\' events'
      },
      MONTHLY: {
        MONTHLY: 'on the last month\'s events',
        radio: 'Monthly: report on the last month\'s events'
      },
      CURRENT_MONTH: {
        CURRENT_MONTH: 'on the current month\'s events',
        radio: 'Current month: report on the current month\'s events'
      }
    },
    recurrence: {
      DAILY: 'Daily',
      WEEKLY: 'Weekly',
      MONTHLY: 'Monthly',
      END_OF_FLIGHT: 'Once when line item ends'
    },
    allActive: 'Report on all active objects'
  },
  reporting: {
    invalid: {
      parameter: 'Invalid parameter: {{param}}'
    }
  },
  role: {
    role: 'Role',
    plural: 'Roles',
    TEAM: 'Team',
    NETWORK: 'Network'
  },
  application: {
    ADVERTISING: 'Advertising',
    DATA: 'Data'
  },
  search: {
    search: 'Search',
    aggregation: {
      advertiser: 'Advertiser',
      dimensions: 'Dimensions',
      country: 'Country',
      earningsAccount: 'Earnings Account',
      publisher: 'Publisher',
      location: {
        CITY: 'City',
        DISTRICT: 'District',
        REGION: 'Region',
        COUNTRY: 'Country',
        CONTINENT: 'Continent',
        POSTCODE: 'Postcode'
      },
      endDate: {
        endDate: 'End Date',
        last24Hours: '@:search.aggregation.lastModifiedIn.last24Hours',
        last7Days: '@:search.aggregation.lastModifiedIn.last7Days',
        last30Days: '@:search.aggregation.lastModifiedIn.last30Days'
      },
      exclusionLabels: 'Exclusion Labels',
      executionState: {
        executionState: 'Execution State',
        not_ready: 'Not ready',
        running: 'Running'
      },
      indexerState: {
        indexerState: 'Indexer State',
        pending: 'Pending'
      },
      siteUrl: 'Site URL',
      rateControlLabels: 'Rate Control Labels',
      labels: 'Labels',
      lastModifiedBy: 'Last Modified By',
      lastModifiedIn: {
        lastModifiedIn: 'Last Modified',
        last24Hours: 'Last 24 Hours',
        last7Days: 'Last 7 Days',
        last30Days: 'Last 30 Days'
      },
      matchingLabels: {
        matchingLabels: 'Matching Labels'
      },
      networkName: 'Network',
      objectState: {
        objectState: 'Object State',
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
        HIDDEN: 'Hidden'
      },
      objectiveTypes: {
        objectiveTypes: 'Objective Type',
        impression: 'CPM',
        click: 'CPC',
        viewableImpression: 'Viewable Imp.'
      },
      startDate: {
        startDate: 'Start Date',
        last24Hours: '@:search.aggregation.lastModifiedIn.last24Hours',
        last7Days: '@:search.aggregation.lastModifiedIn.last7Days',
        last30Days: '@:search.aggregation.lastModifiedIn.last30Days'
      },
      tier: 'Tier',
      marketplace: {
        types: {
          EarningsAccount: 'Publisher'
        }
      },
      types: {
        types: 'Type',
        Advertiser: 'Advertiser',
        Ad: 'Ad',
        AdUnit: 'Ad Unit',
        LineItem: 'Line Item',
        Creative: 'Creative',
        AxProduct: 'Marketplace Product',
        ReportTemplate: 'Report Template',
        CreativeSet: 'Creative Set',
        Layout: 'Layout',
        LayoutInclude: 'Layout Include',
        Site: 'Site',
        Order: 'Order',
        User: 'User',
        RateControlLabel: 'Rate Control Label',
        ExternalAdUnit: 'External Ad Unit',
        ExclusionLabel: 'Exclusion Label',
        MatchingLabel: 'Matching Label',
        EarningsAccount: 'Earnings Account',
        UserSegment: 'User Segment',
        Label: 'Label'
      },
      userState: {
        userState: 'User State',
        approved: 'Approved',
        reserved: 'Reserved'
      },
      validationState: {
        validationState: 'Validation State',
        pending: 'Pending'
      }
    },
    results: {
      compact: 'Compact results',
      full: 'Full results',
      summary: 'Showing <strong>{{start | number}}-{{end | number}}</strong> of <strong>{{total | number}}</strong>',
      shortSummary: 'Showing <strong>{{start | number}}-{{end | number}}</strong> of <strong>{{total | number}}</strong>',
      zero: 'No results found'
    }
  },
  site: {
    site: 'Site',
    siteUrl: 'Site URL',
    plural: 'Sites'
  },
  siteGroup: {
    siteGroup: 'Site Group',
    plural: 'Site Groups'
  },
  firstPartyAudience: {
    firstPartyAudience: 'First-Party Audience',
    plural: 'First-Party Audiences',
    publishState: {
      APPROVED: 'Approved',
      INACTIVE: 'Inactive'
    }
  },
  ruleset: {
    ruleset: 'Ruleset',
    plural: 'Rulesets',
    type: {
      INVENTORY: 'Inventory',
      TARGETING: 'Targeting',
      AUDIENCE: 'Audience',
      SIZE: 'Creative Size'
    }
  },
  theme: {
    mariner: 'Mariner',
    'solarized.dark': 'Solarized Dark'
  },
  tier: {
    tier: 'Tier',
    plural: 'Tiers'
  },
  timeGroup: {
    today: 'Today',
    thisWeek: 'This week',
    lastWeek: 'Last week',
    thisMonth: 'This month',
    lastMonth: 'Last month',
    older: 'Older'
  },
  timeToLive: {
    timeToLive: 'Time to Live',
    FIXED_DURATION: 'Fixed duration',
    TODAY: 'Today',
    THIS_WEEK: 'This week',
    THIS_MONTH: 'This month',
    UNTIL_MONDAY: 'Until Monday',
    UNTIL_TUESDAY: 'Until Tuesday',
    UNTIL_WEDNESDAY: 'Until Wednesday',
    UNTIL_THURSDAY: 'Until Thursday',
    UNTIL_FRIDAY: 'Until Friday',
    UNTIL_SATURDAY: 'Until Saturday',
    UNTIL_SUNDAY: 'Until Sunday'
  },
  user: {
    user: 'User',
    plural: 'Users',
    profile: 'User Profile'
  },
  userProfile: {
    userProfile: 'User Profile'
  },
  userSegment: {
    userSegment: 'User Segment',
    plural: 'User Segments'
  },
  validation: {
    required: "Required",
    alphanumeric: 'Can only contain alphanumeric characters and underscores (no spaces).',
    date: 'Must be a valid date',
    email: "Must be a valid email",
    number: "Must be a numeric value",
    min: "The minimum value is {{min}}",
    max: "The maximum value is {{max}}",
    minlength: "Must be at least {{minlength}} characters long",
    pattern: "The content must be {{minLength}}-{{maxLength}} characters in length.",
    patternInteger: "Value must be a whole non-negative number",
    patternDecimal: "Value must be a non-negative decimal number",
    parse: "The content is not in the expected format",
    url: "Must be a valid url",
    htmlIdFormat: "Must be a valid HTML ID (no spaces, must start with a letter)",
    idFormat: "The ID can only contain lowercase alphanumeric characters, dashes and underscores.",
    error: {
      validation: '<strong>Save Blocked Due to Invalid Data</strong><br>Address the errors listed to re-enable saving this item.',
      'invalid.email.address': '@:validation.email',
      'must.be.greater.than.or.equal': '{{value}} must be greater than {{minValue}}.',
      'non.empty': '@:validation.required'
    },
    warning: {
      validation: '<strong>Ad-Serving Blocked</strong><br>Address the warnings to enable ad serving for this item.'
    }
  },
  "error.auth.failed": "We couldn't sign you in. The credentials don't match any account.",
  "error.auth.failed.2fa": "We couldn't sign you in. Your 2FA code is not valid.",
  "error.sign.in.blocked": "You are not registered as a user here. Sign in at {{uiAddress}}.",
  workspace: {
    workspace: 'Dashboard',
    plural: 'Dashboards',
    type: {
      PERSONAL: 'Personal',
      NETWORK: 'Network',
      TEAM: 'Team'
    },
    widget: {
      widget: 'Dashboard Widget',
      plural: 'Dashboard Widgets'
    }
  },
  ais: {
    frontPage: `
      <div style="text-align:center">
        <img src="https://cdn.adnuntius.com/img/ais/Logo_THAN_2022.png">
      </div>
      <h3 style="text-align: center; margin: 0">Thailand Ads Network</h3>
    `
  },
  d966: {
    frontPage: `
      <div>
        <p style="text-align: center"><img src="https://cdn.adnuntius.com/img/philstar/logo.png"></p>
      </div>
      <h3>Portal for Self-Advertising</h3>
      <p>Philstar.com is one of the country's leading and most recognized digital brands in journalism. It is a top destination for a broad range of content, be it social, political, business, sports, entertainment or lifestyle news.</p>
    `
  },
  blueoryxcalt: {
    frontPageTopContent: `
      <div style="position:absolute;top: 50px; left: 50px;"><img src="https://assets.adnuntius.com/E05BHs-mD0DCa-1-osghR8fnvRs07Eqz1V2MF3X3QCc.png"></div>
    `,
    frontPage: `
      <p><img src="https://assets.adnuntius.com/YFD8yjXCdGYGxXr9MirC6dZ_jxUIDjUHbJN4NaQZU_Q.png" style="max-width: 300px"></p>
      <p style="color: white; font-size: 17px">Access a platform built for streamlined programmatic advertising. Manage your campaigns with a competitive edge and efficiency, all in one place.</p>
    `
  },
  nkjm: {
    frontPageTopContent: `
        <div style="position:absolute;top: 50px; left: 50px;"><img src="https://assets.adnuntius.com/ZxrPk71X0QRHz9VjoaGyubjZyPRrXl8CMd0SZJMwy7c.png"></div>
    `,
    frontPage: `
      <h2 style="font-size: 45px">The One-stop Shop to the French Media</h2>
      <p><img src="https://www.mediasquare.fr/wp-content/themes/mediasquare/assets/point-gris-little.svg" style="margin: 10px 0;"></p>
      <p style="font-size: 16px; color: #555;margin-bottom: 60px">MediaSquare is the union of all the French media on offer in one single digital advertising market place, allowing you to reach more than 44 million individuals each month.</p>
    `
  },
  lossReason: {
    LOW_RANKED: "Low Ranked",
    LINE_ITEM_SPONSORSHIP: "Beaten by Sponsored Ad",
    CREATIVE_SPONSORSHIP: "Beaten by Sponsored Ad",
    PAGINATION: "Ad Unit full",
    CREATIVE_DEDUPLICATION: "De-duplication",
    LINE_ITEM_DEDUPLICATION: "De-duplication",
    COMPETITOR_EXCLUSION: "Competitor Exclusion",
    FLOOR_PRICE: "Floor Price",
    COMPANION: "Companion Ad Exclusion",
    EXTERNAL_OUTBID: "Low Ranked"
  }
};
export {ADN_TRANSLATIONS};
